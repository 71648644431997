@font-face {
  font-family: "RiftSoft-Bold";
  src: url(../fonts/RiftSoft-Bold.otf) format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Urbane-Medium";
  src: url(../fonts/Urbane-Medium.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  /* font-family: "urbane-rounded"; */
  font-size: 14px;
  letter-spacing: 0;
  color: #666;
  font-weight: 500;
}
#footer {
  padding-top: 80px;
  z-index: 0;
  margin-top: -50px;
}

.confirm-report-post {
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
  width: 75%;
  max-width: 320px;
}

.confirm-report-post-text {
  padding: 20px 20px 0px 20px;
  color: #492409;
  font-family: Urbane-Medium;
}

.confirm-report-post hr,
.options-overlay-option hr {
  margin-bottom: 0px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.confirm-report-post-ok {
  color: #4f87ef;
  font-family: Urbane-Medium;
  margin: auto;
  font-size: 16px;
}

.confirm-report-post-cancel {
  border-right: 2px solid rgba(0, 0, 0, 0.1);
}

img.report-post-exclamation-icon {
  height: 30px;
  width: 30px;
  object-fit: contain;
  margin-bottom: 10px;
}

.report-post-btn {
  color: #b52121;
  font-size: 20px;
}

.options-overlay-option {
  text-align: center;
  padding: 10px;
  display: block;
  background-color: #fff;
  border-radius: 10px;
  margin: 5px 7px;
  font-size: 17px;
  font-family: Urbane-Medium;
  color: #707070;
}

.paths-web-container .container {
  overflow: unset !important;
}

.paths-web-content:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  background: inherit;
  z-index: 1;
  height: 17px;
  bottom: 0;
  visibility: hidden;
}

.paths-sign-up-content {
  background: transparent;
  text-align: center;
  right: 0;
}

.paths-sign-up p {
  font-size: 13pt;
  font-family: Urbane-Medium;
  color: #707070;
}

.paths-sign-up img {
  height: 45px;
  width: 145px;
}

.sign-up-button-div {
  background-color: #8cbf89;
  height: 40px;
  width: 105px;
  border-radius: 10px;
  text-align: center;
}

.sign-up-button-div p {
  font-weight: bold;
  /* font-family: 'rift-soft'; */
  font-size: 20pt;
}

.paths-sign-up.make-me-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 50px 15px;
}

.btn-headersection {
  padding: 5px 30px !important;
  border: none;
  font-size: 27px !important;
  background: #8cbf89 !important;
  color: #ffffff !important;
  border-radius: 10px;
  font-family: RiftSoft-Bold;
  font-weight: bold;
  cursor: pointer;
}

span.d-inline-block.sign-up-btn-headersection {
  background: #8cbf89;
  color: #fff;
  font-weight: bold;
  padding: 1px 15px 0px 15px;
  border-radius: 10px;
  font-size: 12px;
  font-family: RiftSoft-Bold;
  text-transform: uppercase;
}

span.d-inline-block.logout-btn {
  background: #f19a7e;
  color: #fff;
  font-weight: bold;
  padding: 1px 15px 0px 15px;
  border-radius: 10px;
  font-size: 12px;
  font-family: RiftSoft-Bold;
  text-transform: uppercase;
}

.btn-personal-page {
  padding: 2px 20px !important;
  border: none;
  font-size: 13px !important;
  background: #8cbf89 !important;
  color: #ffffff !important;
  border-radius: 15px;
  /* font-family: rift-soft; */
  font-weight: bold;
  cursor: pointer;
}

.user-profile-back-arrow {
  margin-left: 5px;
}

.user-profile-see-personal-page {
  margin: auto;
  text-align: center;
  margin-bottom: -30px;
  margin-top: 15px;
}

.user-profile-copy-url {
  margin-top: -40px;
  color: #8cbf89;
  text-align: center;
  font-size: 15px;
  font-family: Urbane-Medium;
}

.feed-post-comment {
  margin-left: 0px;
  margin-right: 0px;
  background-color: #fbf9f6;
}

.post-comment {
  padding: 0px;
}

.post-comment-box {
  padding-left: 35px;
  padding-right: 24px;
  justify-content: flex-end;
}

.comment-warning {
  padding: 0 40px;
}

.paths-web-content {
  /* padding: 0; */
  position: relative;
  z-index: 1;
  /* min-height: 50vh; */
  /* margin: -4px 0px -20px; */
  background-color: #fbf9f6;
  /* border-top-left-radius: 20px; */
  /* border-top-right-radius: 20px; */
  /* border-bottom-left-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
}

.sign-up-footer img {
  height: 45px;
  width: 110px;
  object-fit: contain;
}

.sign-up-footer {
  padding-top: 380px;
}

.login-sign-up-footer {
  padding-top: 380px;
}

.login-sign-up-footer img {
  height: 45px;
  width: 110px;
  object-fit: contain;
}

.paths-background {
  /* padding: 0 15px; */
  padding: 0;
  background: #fbf9f6;
}

.error .paths-web-error {
  color: #fff;
  text-align: center;
  border-radius: 8px;
  margin: 30px auto;
  font-size: 20px;
  border: solid 2px #ef3c23;
  background: #f79868;
  padding: 10px;
}

.parent-post-error p {
  margin-bottom: 0;
}

.parent-post-error a {
  color: #fff;
  font-size: 1rem;
  text-decoration: underline;
  word-break: break-all;
}

.post-background {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: 100% 100% !important;
  background-position: center !important;
  filter: blur(60px);
}

/*Navigation Bar Styling start*/
.navbar-path {
  overflow: hidden;
  margin-left: 0px;
}

.navbar-path.tabs li {
  float: left;
  position: relative;
}

.navbar-path li {
  margin: 0 5px;
}

.navbar-path li:first-child {
  margin-left: 0;
}

.navbar-path li:hover {
  cursor: pointer;
}

.anchor_style img {
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  border-radius: 100% !important;
  display: inline-block !important;
  margin-bottom: -5px;
  border: 2px solid #f79868;
  margin: 5px;
  position: relative;
  z-index: 9;
  object-fit: cover;
}

.add-me-icon {
  height: 50px;
  margin: 5px;
}

.marker-icon i {
  font-size: 35px !important;
  /* margin-bottom: 10px; */
}

.icon-style-plus i {
  font-size: 60px;
  background: #c2bcaf;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;
}

.icon-plus i {
  font-size: 60px;
  background: #c2bcaf;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;
  padding-left: 15px;
}

/*Account Settings Section*/
.account-info-section {
  margin: 30px;
  position: relative;
}

.profile-picture-container {
  height: 150px;
  width: 150px;
  margin: 0 auto;
  border: 3px solid #f26739;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.profile-upload {
  text-align: right;
  margin-top: 100px;
}

.profile-upload > input {
  display: none;
}

.profile-upload .custom-profile-uploader {
  cursor: pointer;
  background: #cb3727;
  padding: 10px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
}

.profile-picture-wrapper .error {
  margin-top: 10px;
}

.edit-user-form label {
  min-width: 100px;
  color: #585858;
}

.edit-user-form .input-btn {
  border: none;
  border-bottom: 2px solid #cecece;
  text-align: left;
  width: calc(100% - 120px);
  outline: none;
  color: #585858;
}

.edit-user-form .name-container {
  margin-bottom: 15px;
}

.edit-user-form .username-field {
  margin-bottom: 0;
  max-width: 480px;
}

.edit-user-form .username-field input {
  margin-bottom: 0;
}

.edit-user-form .username-field p {
  text-align: right;
  margin: 0;
  font-size: 12px;
  margin-right: 10px;
}

.edit-user-form .profile-url-container {
  margin-bottom: 0;
  text-align: right;
  max-width: 575px;
  font-size: 12px;
}

.myfeed-container {
  margin: 2rem auto 1.5rem;
  text-align: center;
}

.myfeed-container .btn-myfeed {
  background: linear-gradient(
    to bottom,
    #f79868,
    #f68554,
    #f47042,
    #f25931,
    #ef3c23
  );
  color: #fff;
  margin-left: 10px;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  /* font-family: 'urbane-rounded'; */
}

.myfeed-container .btn-myfeed i {
  font-weight: 700;
  padding-left: 5px;
}

.edit-user-form .url-value {
  color: #f26739;
  min-width: auto;
}

.edit-user-form .privacy-checkbox {
  zoom: 1.5;
}

.edit-user-form .list-country-code {
  background: none;
  padding: 4px 10px 5px 10px;
  border-radius: 40px;
  border-color: #585858;
  color: #585858;
  max-width: 100px;
  outline: none;
}

.edit-user-form .btn-update {
  text-transform: uppercase;
  /* font-family: 'rift-soft'; */
  padding: 3px 30px;
  border-radius: 8px !important;
  font-size: 24px;
  line-height: 1;
  background: linear-gradient(
    to top,
    #39b44a,
    #52b947,
    #67bd44,
    #79c142,
    #8bc540
  );
  border: 1px solid;
  color: #fff;
  outline: none;
  min-width: 100px;
}

.edit-user-form input[type="checkbox"] {
  height: 0;
  width: 0;
}

.edit-user-form input[type="checkbox"] + label {
  position: relative;
  margin: 0.6em 0;
  align-items: center;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
  min-width: auto;
  vertical-align: middle;
}

.edit-user-form input[type="checkbox"] + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background: transparent;
  border: 2px solid #9e9e9e;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.edit-user-form input[type="checkbox"] + label:hover > span,
.edit-user-form input[type="checkbox"]:focus + label > span {
  background: rgba(255, 255, 255, 0.1);
}

.edit-user-form input[type="checkbox"]:checked + label > span {
  border-color: #f26739;
}

.edit-user-form input[type="checkbox"]:checked + label > span:before {
  content: "";
  position: absolute;
  top: -2px;
  left: 5px;
  border-right: 3px solid #f26739;
  border-bottom: 3px solid #f26739;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  height: 1rem;
  width: 0.5rem;
}

.edit-user-form .error {
  margin-left: 110px;
  display: inline-block;
}

.privacy-wrapper {
  margin-left: 110px;
}

.app-store-img:hover,
.play-store-img:hover {
  cursor: pointer;
}

.edit-user-form label.privacy-label {
  padding-left: 10px;
  vertical-align: middle;
  text-align: left;
  min-width: 85%;
  max-width: 200px;
}

.edit-user-form .logoutalert {
  width: auto;
  top: 0;
  right: 0;
}

.edit-user-form .paths-phone.account-settings {
  display: inline-block;
  width: 100px;
  padding-right: 5px;
}

.edit-user-form .paths-phone.account-settings .PhoneInputCountry {
  height: 30px;
  margin: 0;
  width: auto;
}

.edit-user-form .paths-phone.account-settings input.PhoneInputInput {
  display: none;
}

.edit-user-form .custom-mobile-number {
  display: inline-block;
  width: calc(100% - 120px);
  position: relative;
}

.edit-user-form .mobile-number-field {
  display: inline-block;
  width: 95%;
  padding-left: 75px;
  margin-left: 5%;
}

.edit-user-form .phone-code {
  border: none;
  border-radius: 0;
  left: 0px;
  top: -5px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.p.signup-link.text-center {
  font-family: Urbane-Medium;
}

.privacy-sublabel {
  display: block;
  font-size: 12px;
}

.hidden-qr-code {
  display: none;
}

.account-info-section .section-divider {
  border-top: 3px solid #f26739;
  margin-bottom: 30px;
}

.delete-section-links {
  margin-bottom: 20px;
}

.delete-section-links .items {
  margin: 0;
  padding: 10px 20px;
  border-top: 1px dashed #cecece;
}

.delete-section-links .items:last-child {
  border-bottom: 1px dashed #cecece;
}

.delete-section-links .items a {
  color: #585858;
  /* font-family: 'urbane-rounded'; */
  text-decoration: none;
}

.delete-section-links .items i {
  font-weight: 700;
}

.delete-section-links .items .link-label {
  margin-left: 10px;
}

.delete-message {
  text-align: right;
  /* font-family: 'urbane-rounded'; */
  font-size: 11px;
  color: #585858;
  margin-bottom: 0;
}

.delete-account-button-container .delete-logo {
  text-align: center;
  margin: 5px;
}

.delete-account-button-container .delete-logo i {
  font-size: 70px;
}

.delete-account-button-container .delete-logo i {
  font-size: 70px;
}

.btn-delete-account {
  background: linear-gradient(
    to bottom,
    #ff0000,
    #f21b0f,
    #e52819,
    #d83021,
    #cb3727
  );
  color: #fff;
  padding: 2px 15px;
  border-radius: 8px;
  text-transform: uppercase;
  /* font-family: 'rift-soft'; */
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.confirm-delete-account {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

.confirm-delete-account-container {
  max-width: 520px;
  margin: 20px auto 30px;
  padding: 0 10px;
  color: #585858;
}

.delete-account-title {
  margin: 10px;
  font-size: 16px;
  font-weight: 700;
}

.confirm-delete-account .btn-delete-account {
  font-family: inherit;
  padding: 7px 35px;
  font-size: 14px;
  text-transform: unset;
}

.confirm-delete-account .paths-logo {
  max-width: 150px;
  margin: 10px 0;
}

.paths-footer-link {
  cursor: pointer;
}

.collab-tag-operations-header {
  text-align: center;
  padding-top: 20px;
  background-color: #ede9df;
  padding-bottom: 1px;
}

.collab-tag-operations-header-first {
  font-size: 30px;
  color: #c2bcaf;
  margin-bottom: 0px;
  font-weight: bold;
  font-family: RiftSoft-Bold;
}

.collab-tag-operations-header-second {
  font-size: 18px;
  color: #707070;
  font-family: Urbane-Medium;
}

.collab-tag-background-image-section {
  margin-top: -16px;
}

.collab-tag-background-image {
  height: 280px;
  width: max-content;
}

.collab-tab-section {
  /* margin-top: -310px; */
  background: url(../images/icons/wooden-background.png);
  background-size: cover;
}

.collab-tag-operations-footer {
  padding: 25px 15px 20px 15px;
}

.save-qr-collab-tag-operation {
  padding-bottom: 10px;
  font-size: 18px;
  font-family: Urbane-Medium;
}

.save-qr-only-operation {
  /* margin-left: -90px; */
  /* padding-right: 0; */
  text-align: center;
}

.confirm-entry-save-qr-only-operation {
  margin-left: -90px;
  text-align: center;
}

.print-collab-tag-operation {
  padding-bottom: 10px;
  color: #707070;
  font-size: 18px;
  font-family: Urbane-Medium;
}

.more-contribution-screen-icon {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.collab-tag-done-btn {
  font-size: 25px;
  margin-bottom: 0px;
  color: #8cbf89;
  font-weight: bold;
  /* text-align: right; */
  text-align: center;
  padding: 0px 10px;
  font-family: RiftSoft-Bold;
}

.order-collab-tag-operation {
  font-size: 18px;
  font-family: Urbane-Medium;
}

.profile-header-web-feed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.user-profile-collab-tag-caption {
  color: #f19a7e;
  font-family: Urbane-Medium;
  max-width: 60%;
  margin: auto;
  text-align: center;
  font-size: 12px;
}

.post-header-collab-tag-caption {
  color: #f19a7e;
  font-family: Urbane-Medium;
  position: absolute;
  right: 115px;
  width: 100px;
  top: 20px;
  font-size: 7px;
  text-align: right;
}

.download-qr-link {
  text-align: center;
  /* margin-top: 270px; */
  color: #492409;
  font-family: Urbane-Medium;
}

span.pl-1.sign-up-toggle {
  color: #8cbf89;
}

.qr-links {
  text-align: center;
  /* margin-top: 250px; */
  color: #492409;
  font-family: Urbane-Medium;
}

i.fa.fa-circle-o-notch.fa-spin {
  color: #f19a7e;
}

.user-profile-qr-code-image {
  /* text-align: center; */
  margin-top: -80px;
  margin-bottom: -80px;
}

.qr-code-header {
  /* padding: 10px; */
  transform: scale(0.6) rotate(-3deg) translate(10px, 0px);
  box-shadow: 0 0 15px #ccc;
}

.qr-code-composite {
  margin-top: 20px;
}

.qr-code-header-without-margin {
  box-shadow: 0 0 15px #ccc;
  /* display: none; */
  min-height: 1015px;
  max-width: 580px;
  margin-bottom: 35px;
  background-color: #ffffff;
}

.popup-qr-code-header {
  padding: 10px 0px;
  transform: scale(0.585) rotate(-5deg) translate(0px, -93px);
  box-shadow: 0 0 15px #ccc;
  /* margin-top: -30px; */
  /* margin-bottom: -30px; */
  margin: auto;
  max-height: 320px;
  max-width: 300px;
  min-width: 200px;
  width: 100%;
}

.user-profile-qr-code-container-popup {
  padding: 10%;
  margin-top: -70px;
}

.download-collabtag-now {
  text-align: center;
  font-size: 40px;
  color: #8cbf89;
  font-family: Urbane-Medium;
}

.download-collabtag-now:hover {
  cursor: pointer;
  color: #8cbf89;
  text-decoration: none;
}

.collab-tag-download-spinner {
  padding-left: 5px;
  text-align: center;
  font-size: 40px;
  color: #8cbf89;
}

p.collab-tag-popup-title {
  text-align: center;
  color: #492409;
  font-size: xx-large;
}

p.back-to-entry-from-collab {
  font-size: 15px;
  text-align: center;
  font-family: Urbane-Medium;
  color: #492409;
}

p.back-to-entry-from-collab:hover {
  cursor: pointer;
}

.circle {
  width: 34px;
  height: 34px;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 11px !important;
  object-fit: cover;
}

.circle-without-margin {
  width: 70px;
  height: 70px;
  background-color: white;
  border-radius: 35px;
  margin-bottom: 11px !important;
  object-fit: cover;
}

#attached-qr-code {
  height: auto !important;
  width: 100% !important;
}

a.nav-link.anchor_style.user-profile-card {
  color: #ffffff;
  background-color: #f19a7e;
  padding: 1px 10px !important;
  border-radius: 0px 15px 0 0 !important;
  cursor: pointer;
  z-index: 3;
}

.qr-code-header-content {
  display: flex;
  flex-direction: row;
  padding: 5px 10px 0px 10px;
  margin-bottom: -20px;
  background-color: #fff;
}

img.user-profile-card-header-icon {
  height: 35px;
  width: 35px;
  margin-top: 5px;
}

img.user-profile-card-header-icon-without-margin {
  height: 70px;
  width: 70px;
  margin-top: 10px;
  margin-bottom: 40px;
  object-fit: contain;
}

p.user-profile-card-header-text {
  font-size: 14px;
  font-weight: bold;
  color: #f19a7e;
  font-family: RiftSoft-Bold;
  padding: 0px 10px;
}

p.user-profile-card-header-text-without-margin {
  font-size: 30px;
  font-weight: bold;
  color: #f19a7e;
  line-height: 38px;
  font-family: RiftSoft-Bold;
  padding: 10px 10px 0px 10px;
}

p.display-user-name {
  color: #707070;
  font-family: Urbane-Medium;
  font-size: 10pt;
  margin-top: -20px;
}

p.signup-header-top {
  margin-bottom: 0px;
  font-size: 8px;
  color: #707070;
  text-align: center;
  font-family: "Urbane-Medium";
}

p.signup-header-bottom {
  text-align: center;
  color: #f19a7e;
  text-transform: uppercase;
  font-family: "RiftSoft-Bold";
  font-size: 14px;
  margin-bottom: 30px;
}

p.signup-header-bottom-first {
  text-align: center;
  color: #f19a7e;
  text-transform: uppercase;
  font-family: "RiftSoft-Bold";
  font-size: 14px;
  margin-bottom: -5px;
}

p.signup-footer-header {
  text-align: center;
  color: #f19a7e;
  text-transform: uppercase;
  font-family: "RiftSoft-Bold";
  font-size: 14px;
  margin-bottom: -25px;
  padding-top: 25px;
}

p.signup-footer-bottom {
  text-align: center;
  color: #f19a7e;
  text-transform: uppercase;
  font-family: "RiftSoft-Bold";
  font-size: 14px;
}

.sign-in-toggle {
  text-align: center;
  color: #8cbf89;
  font-family: "Urbane-Medium";
}

.sign-in-toggle span:hover {
  cursor: pointer;
}

.linkify-post-description {
  text-decoration: none;
  color: #8cbf89;
}

.linkify-post-description:hover {
  text-decoration: none;
  color: #8cbf89;
  cursor: pointer;
}

.user-profile-card img {
  height: 35px;
  width: 35px;
  background-color: #ffffff;
  border-radius: 100% !important;
  display: inline-block !important;
  margin-bottom: -5px;
  border: 2px solid #f79868;
  margin: 5px;
  position: relative;
  z-index: 9;
  object-fit: cover;
}

img.add-me-icon.user-profile-card {
  height: 35px;
}

img.add-me-icon.user-profile-card-without-margin {
  height: 70px;
  width: 70px;
  object-fit: contain;
}

.post-header-text.user-profile-card {
  flex: 1;
  text-align: center;
  color: #ffffff;
  padding: 0px 0;
  min-height: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.post-header-text.user-profile-card-high-resolution {
  flex: 1;
  text-align: center;
  color: #ffffff;
  padding: 0px 0;
  min-height: 90px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

p.post-title.user-profile-card {
  margin: 0;
  padding: 0;
  font-size: 12pt;
  font-weight: bold;
  font-family: RiftSoft-Bold;
  color: #ffffff;
  text-align: center;
  word-break: break-word;
  line-height: 16px;
}

p.post-title.user-profile-card-without-margin {
  margin: 0;
  padding: 0;
  font-size: 25pt;
  font-weight: bold;
  font-family: RiftSoft-Bold;
  color: #ffffff;
  text-align: center;
  word-break: break-word;
  /* max-height: 60px; */
  /* white-space: nowrap; */
  width: 100%;
  line-height: 25pt;
  margin-top: 5px;
  margin-bottom: 5px;
}

p.post-place.user-profile-card-without-margin {
  font-size: 15pt;
  margin-bottom: 0px;
  margin-top: -15px;
  color: #676767;
  font-family: Urbane-Medium;
}

span.post-user-name.user-profile-card {
  margin: 0;
  padding: 0;
  font-size: 8pt;
  font-family: Urbane-Medium;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
}

span.post-user-name.user-profile-card-without-margin {
  margin: 0;
  padding: 0;
  font-size: 15pt;
  font-family: Urbane-Medium;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
}

span.post-created-by {
  text-transform: capitalize;
}

p.post-place.user-profile-card {
  font-size: 8pt;
  color: #676767;
  margin-bottom: 0;
  font-family: Urbane-Medium;
}

p.single-post-description.user-profile-card {
  word-break: break-all;
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 9px;
  padding-bottom: 5px;
}

p.single-post-description.user-profile-card-without-margin {
  word-break: break-all;
  padding-top: 0px;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 25px;
  padding-bottom: 5px;
  min-width: 100%;
  display: block;
  font-family: Urbane-Medium;
  background-color: #ffffff;
}

.download-qr-link p:hover {
  cursor: pointer;
}

.qr-links p:hover {
  cursor: pointer;
}

.user-icon-default-feed {
  height: 90px;
  width: 90px;
  background-color: transparent;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 2px solid #f79868;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
}

.user-icon-default-feed:hover {
  cursor: pointer;
}

.user-icon-feed {
  height: 90px;
  width: 90px;
  background-color: transparent;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #f79868;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
}

.user-icon-feed:hover {
  cursor: pointer;
}

.user-profile-section {
  border-radius: 15px;
  box-shadow: 0px 4px 3px #b3b3b37a;
}

.user-profile-section-popup.make-me-sticky {
  position: sticky;
  top: 0;
  padding: 10px 0px;
}

.user-profile-section.make-me-sticky {
  position: sticky;
  top: 0;
  padding: 10px 0px;
}

.user-profile-qrCodeContainer {
  position: relative;
  /* right: 15px; */
  margin: 45px;
  margin-bottom: 0px;
}

/* .downloadable-qrCodeContainer {
  position: relative;
  margin: 45px;
  margin-bottom: 0px;
} */

.user-profile-qr-code-container {
  margin-top: -60px;
}

.welcome-overlay {
  padding: 30px 20px;
  text-align: center;
  max-width: 400px;
  margin: auto;
}

img.welcome-overlay-paths-logo {
  height: 80px;
  width: 300px;
  object-fit: contain;
}

.welcome-overlay-welcome-message h1 {
  color: #f19a7e;
  text-transform: uppercase;
  font-weight: 700;
  font-family: RiftSoft-Bold;
  font-size: 50px;
  padding-top: 50px;
}

.welcome-overlay-welcome-message h3 {
  color: #f19a7e;
  text-transform: uppercase;
  font-family: "RiftSoft-Bold";
  font-weight: bold;
  margin-top: -15px;
  margin-bottom: 30px;
  font-size: 23px;
}

.welcome-overlay-welcome-message p {
  font-family: Urbane-Medium;
  color: #492409;
  font-size: 15px;
}

.welcome-overlay-welcome-message img {
  height: 85px;
  width: 65px;
  object-fit: contain;
}

.welcome-overlay-further-guide p {
  font-family: Urbane-Medium;
  color: #f19a7e;
  font-size: 15px;
  padding: 30px 0px 30px 0px;
}

.welcome-overlay-register-options p.header-sign-in-text {
  font-size: 13px !important;
  padding: 0px !important;
}

.entrypage-user-profile-qrCodeContainer {
  position: relative;
  /* right: 28px; */
  /* margin: 45px; */
  background-color: #ffffff;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.display-name-feed {
  color: #f19a7e;
  font-family: RiftSoft-Bold, serif;
  font-weight: 700;
  font-size: 20pt;
  text-transform: uppercase;
}

.col-10.user-info {
  color: #676767;
  /* font-family: 'urbane-rounded'; */
  font-size: 8pt;
}

.user-info-icon {
  display: flex;
  flex-direction: row;
}

.info-item {
  padding: 5px 35px 5px 35px;
  /* font-family: 'urbane-rounded'; */
  color: #676767;
  font-size: 9pt;
}

.trash-iocns {
  padding: 5px 0px;
  margin-bottom: 10px;
}

.extra-trash-iocns {
  padding: 5px 0px;
  margin-bottom: 10px;
  margin-left: -25px;
}

.info-icon {
  height: 15px;
  width: 20px;
}

img.message-icon {
  padding-left: 10px;
  height: 15px;
  width: 27px;
}

/* Post Header Section*/
.post-header-title span {
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
  /* font-family: 'rift-soft'; */
  color: #c2bcaf;
}

.post-header-title p {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 20px;
  /* font-family: 'urbane-rounded'; */
  padding-bottom: 10px;
}

.post-header-title .title {
  padding-bottom: 0;
  margin-bottom: 5px;
  /* font-family: 'urbane-rounded'; */
}

.invite-postheader {
  position: relative;
  /* margin-top: -20px; */
  text-align: left;
  /* padding-top: 20px; */
  /* border-top-left-radius: 30px; */
  /* border-top-right-radius: 30px; */
  display: none;
}

.user-feed-post-footer {
  text-align: center;
}

.feed-post-footer-buttons {
  padding-bottom: 20px;
}

.feed-post-footer-buttons img {
  height: 75px;
  width: 250px;
  object-fit: contain;
}

.feed-post-footer-buttons img:hover {
  cursor: pointer;
}

p.feed-post-footer-head {
  color: #f19a7e;
  font-size: 45px;
  font-family: RiftSoft-Bold;
  font-weight: 700;
  line-height: 45px;
  text-transform: uppercase;
}

p.feed-post-footer-section {
  color: #707070;
  font-family: Urbane-Medium;
}

p.feed-post-footer-tail {
  color: #8cbf89;
  font-size: 30px;
  text-transform: uppercase;
  font-family: RiftSoft-Bold;
  font-weight: 100;
}

.collab-tags-header {
  padding: 40px 180px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #fbf9f6;
}

.collab-tags-header-uri {
  padding: 40px 25px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #fbf9f6;
}

.multi-qr-code {
  width: 125px;
  height: 150px;
}

.multi-qr-code-uri {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.collab-tag-header-title {
  display: flex;
}

.back-arrow-icon {
  padding-top: 10px;
}

.headersection-marketplace-icons {
  position: absolute;
  top: -67px;
  right: 0%;
  width: 142px;
  display: flex;
  justify-content: flex-end;
}

.headersection-marketplace-icons img {
  height: 35px;
  width: 40px;
  object-fit: contain;
}

.headersection-buttons {
  position: absolute;
  top: -67px;
  right: 3%;
}

@media (max-width: 650px) {
  .headersection-buttons {
    top: -64px;
  }
}

.ship-address-icon {
  padding: 10px 10px 10px 0px;
  object-fit: contain;
  height: 45px;
  width: 50px;
}

.ship-address-icon-uri {
  padding: 10px 10px 10px 0px;
  object-fit: contain;
  height: 60px;
  width: 70px;
}

p.get-collab-tags-text {
  padding: 15px 15px 10px 0px;
  color: #c2bcaf;
  font-weight: bold;
  font-size: 16px;
}

p.get-collab-tags-text-uri {
  padding: 15px 15px 10px 0px;
  color: #c2bcaf;
  font-weight: bold;
  font-size: 30px;
  font-family: RiftSoft-Bold;
}

.collab-tags-shiping-header {
  color: #707070;
  padding-left: 5px;
  font-size: 16px;
  text-align: left;
  padding-right: 8px;
}

.collab-tags-shiping-header-uri {
  color: #707070;
  padding-left: 5px;
  font-size: 24px;
  text-align: left;
  padding-right: 8px;
  font-family: Urbane-Medium;
}

.collab-tags-choose-quantity {
  padding: 10px 20px 10px 20px;
}

p.choose-quantity-text {
  color: #f19a7e;
  font-weight: bold;
  font-size: 15px;
}

p.choose-quantity-text-uri {
  color: #f19a7e;
  font-weight: bold;
  font-size: 20px;
  font-family: RiftSoft-Bold;
}

.collab-tags-choose-quantity td {
  margin-right: 91px;
  display: inline-block;
}

.collab-tags-total {
  padding: 10px 20px 10px 20px;
  width: 100%;
}

.collab-tags-express-checkout {
  padding: 10px 20px 10px 20px;
  width: 100%;
}

.collab-tags-confirm-purchase {
  padding: 50px 20px 10px 20px;
  width: 100%;
}

.collab-tags-button {
  width: 100%;
  padding: 10px 20px 10px 20px;
}

.collab-tags-button-uri {
  width: 100%;
  padding: 10px 20px 10px 20px;
  text-align: center;
}

.collab-tags-purchase-button {
  width: 100%;
  padding: 50px 20px 10px 20px;
}

.collab-tags-purchase-button-uri {
  width: 100%;
  padding: 50px 20px 10px 20px;
  max-width: max-content;
  margin: auto;
  text-align: right;
}

.collab-tags-confirm-purchase-button-uri {
  width: 100%;
  /* padding: 50px 20px 10px 20px; */
  max-width: max-content;
  margin: auto;
  text-align: right;
}

img.pay_pal_image {
  width: 370px;
  height: 40px;
  /* object-fit: contain; */
}

.collab-tags-total-details {
  text-align: center;
}

.d-flex-radio {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: Urbane-Medium;
}

.radio {
  margin: 0.5rem;
  flex: 1;
  min-width: 100px;
}
.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}
.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: #f4f4f4;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: -0.2em;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #f19a7e;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}
.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  /* border-color: #F19A7E; */
}
.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}
.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: 10px;
}

p.collab-details-first {
  color: #8cbf89;
  font-size: 9px;
  margin-bottom: 0px;
}

p.collab-details-first-uri {
  color: #8cbf89;
  font-size: 13px;
  margin-bottom: 0px;
  font-family: Urbane-Medium;
}

p.collab-qty-details-first-uri {
  color: #707070;
  font-size: 20px;
  font-family: Urbane-Medium;
  margin-bottom: 0px;
}

p.collab-details-second {
  color: #8cbf89;
  font-size: 30px;
  font-weight: bold;
  margin-top: -5px;
  font-family: RiftSoft-Bold;
}

p.collab-details-second-uri {
  color: #8cbf89;
  font-size: 45px;
  font-weight: bold;
  margin-top: -10px;
}

p.collab-price-details-second-uri {
  color: #8cbf89;
  font-size: 50px;
  font-family: RiftSoft-Bold;
  font-weight: bold;
  margin-top: -10px;
}

p.collab-details-third {
  color: #707070;
  font-size: 9px;
  margin-top: -20px;
  margin-bottom: 0px;
}

p.collab-details-third-uri {
  color: #707070;
  font-size: 13px;
  margin-top: -20px;
  margin-bottom: 0px;
  font-family: Urbane-Medium;
}

.high-reslution-collab-tag-review {
  padding: 0px 125px;
}

.collab-tags-payment-uri {
  text-align: center;
  font-family: RiftSoft-Bold;
}

.collab-tags-review-header {
  color: #c2bcaf;
  text-align: center;
  font-size: 30px;
  font-family: RiftSoft-Bold;
  font-weight: bold;
  padding: 15px 20px 40px 20px;
}

.order-detail-title {
  font-size: 12px;
  font-weight: bold;
  color: #f19a7e;
}

.order-detail-title-uri {
  font-size: 15px;
  font-weight: bold;
  font-family: RiftSoft-Bold;
  color: #f19a7e;
  padding: 0px 0px 0px 90px;
}

.order-detail-content {
  color: #707070;
  font-size: 14px;
  font-weight: bold;
}

.order-detail-content-uri {
  color: #707070;
  font-size: 15px;
  font-weight: bold;
  font-family: Urbane-Medium;
  word-break: break-all;
}

.include-memory-section p {
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  /* font-family: 'urbane-rounded'; */
}

.navbar-path li.active .nav-link {
  color: #ffffff;
  background-color: #f19a7e;
  padding: 10px 15px;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
  z-index: 3;
}
.navbar-action {
  padding-top: 5px;
  margin-left: 6px;
  margin-right: 6px;
}
.paths-navbar {
  position: relative;
}
/*Navigation Bar Styling end*/

/*Header Styling start*/
.header-title {
  /* font-family: 'rift-soft'; */
  text-transform: uppercase;
  font-size: 4rem;
  color: #f26739;
}

.header-spacing {
  margin-bottom: 0px !important;
}

.header-para {
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.navbar-path .tabs li:before,
.navbar-path .tabs li:after,
.navbar-path .tabs li a:before,
.navbar-path .tabs li a:after {
  position: absolute;
  bottom: 0;
}

.navbar-path .tabs .active:before,
.navbar-path .tabs .active:after {
  background: #f19a7e;
  z-index: 1;
}

.navbar-path .tabs li:last-child:after,
.navbar-path .tabs li:last-child a:after,
.navbar-path .tabs li:first-child:before,
.navbar-path .tabs li:first-child a:before,
.navbar-path .tabs .active:after,
.navbar-path .tabs .active:before,
.navbar-path .tabs .active a:after,
.navbar-path .tabs .active a:before {
  content: "";
}

.navbar-path .tabs li:before {
  left: -10px;
}

.navbar-path .tabs li:before,
.tabs li:after {
  /* background-color: #FBF9F6 !important; */
  width: 11px;
  height: 24px;
}

.navbar-path .tabs li:before,
.navbar-path .tabs li:after,
.navbar-path .tabs li a:before,
.navbar-path .tabs li a:after {
  position: absolute;
  bottom: 0;
}

.navbar-path li.nav-item.active a {
  float: left;
  padding: 13px 12px 15px 12px;
  text-decoration: none;
  color: #ef3c23;
  background: #ef3c23;
  -webkit-border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  -moz-border-radius-topleft: 15px;
  -moz-border-radius-topright: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.navbar-path .tabs .active a:after,
.navbar-path .tabs .active a:before {
  background: #ffffff;
}

.navbar-path .tabs li:last-child:after,
.navbar-path .tabs li:last-child a:after,
.navbar-path .tabs li:first-child:before,
.navbar-path .tabs li:first-child a:before,
.navbar-path .tabs .active:after,
.navbar-path .tabs .active:before,
.navbar-path .tabs .active a:after,
.navbar-path .tabs .active a:before {
  content: "";
}

.navbar-path .tabs li a:before {
  left: -20px;
}

.navbar-path .tabs li a:after,
.navbar-path .tabs li a:before {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: transparent;
  z-index: 2;
}

.navbar-path .tabs li:before,
.navbar-path .tabs li:after,
.navbar-path .tabs li a:before,
.navbar-path .tabs li a:after {
  position: absolute;
  bottom: 0;
}
.feed-navigation .bg-danger,
.navbar-path .tabs .active a:after,
.navbar-path .tabs .active a:before {
  background-color: #fbf9f6 !important ;
}
.navbar-path .tabs li a:before {
  left: -50px;
  width: 50px;
  height: 40px;
  bottom: -1px !important;
  border-radius: 51px 30px 43px 5px;
  color: transparent;
}

.navbar-path .tabs li a:after {
  right: -40px;
  width: 40px;
  height: 30px;
  bottom: -3px !important;
  border-radius: 5px 0 0 34px;
  color: transparent;
}

.navbar-path .tabs li:after {
  right: -10px;
}

.navbar-path .tabs li:before,
.navbar-path .tabs li:after {
  background: #fbf9f6;
  width: 11px;
  height: 24px;
}

/*Top Header About Us Promise Section Navigation*/
.topheader-nav a.nav-link,
.paths-navbar-brand {
  font-weight: bold !important;
  color: #f26739;
  font-size: large;
}
/*Collab Tab Styling end*/

.navbar-root {
  background-color: #fbf9f6;
  margin-top: -7px;
}

.navbar-root div {
  margin-top: 0px;
}

.qr-code-header ul.nav.tabs.group.nav-pills.collabtab-list {
  padding-top: 0px;
}

.qr-code-header-without-margin .collabtab-list {
  padding-top: 0px;
}

.popup-qr-code-header .collabtab-list {
  padding-top: 0px;
}

.user-profile-qr-code-image .collabtab-list {
  padding-top: 5px;
}

.user-profile-qr-code-image .navbar-root div {
  margin-top: 0.6rem;
}

.popup-qr-code-header .navbar-root div {
  margin-top: 0.6rem;
}

.qr-code-header .navbar-root div {
  margin-top: 0.6rem;
}

.qr-code-header-without-margin .navbar-root div {
  margin-top: 0.6rem;
}

.header-section {
  padding: 10px 0px 0px 0px;
  position: fixed;
  background: #ffffff;
  z-index: 999999;
  width: 100%;
}

.header-section-paths-logo {
  padding-right: 0px;
}

.header-section-paths-logo img {
  max-width: 170px;
}

.header-section-login-buttons-marketplace-icons {
  display: none;
}

.header-section-marketplace-icons img {
  height: 35px;
  width: 40px;
  object-fit: contain;
}

.footer-section {
  padding-right: 10px;
  padding-top: 10px;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  z-index: 999999;
  min-height: 55px;
}

.footer-navigation-links span {
  padding-right: 20px;
  font-family: Urbane-Medium;
  color: #492409;
}

.footer-section-navigation-links p {
  font-size: 9px;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

.footer-marketplace-icons {
  text-align: center;
}

.footer-marketplace-icons img {
  height: 30px;
  width: 80px;
  object-fit: contain;
}

.logged-in-footer-section {
  position: fixed;
  bottom: 0px;
  background-color: #ffffff;
  width: 100%;
  padding: 10px;
  margin-left: -15px;
  z-index: 999999;
}

.logged-in-footer-paths-logo img {
  height: 35px;
  width: auto;
}

img.footer-create-entry-button {
  height: 80px;
  position: fixed;
  bottom: 5px;
  right: 10px;
}

img.feed-footer-shadow {
  transform: rotate(180deg);
  height: 20px;
  width: 100%;
  position: fixed;
  bottom: 55px;
  margin-left: -15px;
}

.logged-in-detail-footer-section {
  padding: 10px 0px;
}

p.footer-colaborate-detail {
  color: #ed977c;
  font-family: Urbane-Medium;
  font-size: 18px;
}

.footer-detail-marketplace-icons {
  text-align: center;
  padding-bottom: 15px;
}

.footer-detail-marketplace-icons img {
  width: 90px;
  object-fit: contain;
}

.detail-footer-section-navigation-links {
  text-align: center;
}

.detail-footer-section-navigation-links p {
  font-size: 9px;
  margin-bottom: 0px;
}

img.close-detail-footer-section {
  height: 25px;
  object-fit: contain;
  position: relative;
  bottom: 0px;
  left: 95%;
  top: -40px;
}

.create-memory {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  align-items: center;
  position: fixed;
  background: #fff;
  top: 0;
  margin-left: -15px;
  z-index: 999;
}

.create-memory img {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

img.create-memory-header-shadow {
  position: fixed;
  top: 84px;
  width: 95%;
  height: 25px;
  z-index: 999;
}

.create-memory-header {
  width: 100%;
  text-align: center;
}

.create-memory p {
  color: #c2bcaf;
  text-transform: uppercase;
  font-size: 30px;
  font-family: "RiftSoft-Bold";
  margin-bottom: 0px;
  font-weight: 700;
}

.create-memory-enter-details-scetion {
  min-height: max-content;
  margin-top: 100px;
  margin-bottom: 180px;
}

.memory-settings-enter-details-scetion {
  min-height: max-content;
  margin-top: 100px;
  margin-bottom: 109px;
}

.create-memory-carousel-description-section {
  padding: 10px;
  box-shadow: 0px 7px 15px #b3b3b37a;
  border-radius: 10px;
  min-height: 200px;
}

.create-memory-tt-section {
  box-shadow: 0px 7px 15px #b3b3b37a;
  border: 2px solid #b3b3b37a;
  border-radius: 10px;
  margin: 30px 5px;
}

.tt-section-header {
  display: flex;
  background-color: #e6e6e6;
}

.tt-section-header p {
  color: #707070;
  text-align: center;
  padding: 10px;
  font-size: 20px;
  width: 100%;
  margin-bottom: 0px;
  font-family: Urbane-Medium;
}

.tt-section-header img {
  height: 15px;
  width: 15px;
  object-fit: contain;
  margin-top: 17px;
  margin-right: 10px;
}

.create-memory-tt-privacy {
  width: 100%;
  text-align: end;
}

.create-memory-tt-title {
  padding: 10px;
  display: flex;
  text-align: center;
}

.create-memory-tt-title p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #b3b3b3;
  word-break: break-word;
  text-align: left;
  padding-right: 50px;
}

.create-memory-tt-title img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  margin-right: 10px;
  margin-bottom: 5px;
}

.tt-title-input-field {
  border: none;
  font-size: 15px;
  color: #c2bcaf;
  font-family: Urbane-Medium;
}

.create-memory-tt-description {
  padding: 20px 10px;
}

.tt-title-description-field {
  width: 100%;
  resize: none;
  border: 1px solid #c2bcaf;
  border-radius: 5px;
  font-size: 17px;
  font-family: Urbane-Medium;
  color: #c2bcaf;
}

.create-memory-tt-section hr {
  margin-top: 0;
  margin-bottom: 0;
  width: 94%;
  border-top: 1px solid #707070;
}

img.create-memory-link-list-icon {
  height: 50px;
  width: 45px;
  object-fit: contain;
  margin: 0;
  padding: 12px 5px;
}

p.link-list-save-button {
  font-size: 20px;
  text-align: end;
  margin-bottom: 0;
  color: #8cbf89;
  font-weight: 700;
  font-family: Urbane-Medium;
}

.create-memory-post-title {
  background: #f19a7e;
  border: 0px;
  max-width: 75%;
  color: #fff;
  font-size: 25px;
  text-align: center;
  font-family: RiftSoft-Bold;
  font-weight: 700;
  text-transform: uppercase;
}

.create-memory-post-title::placeholder {
  color: #fff;
  font-family: RiftSoft-Bold;
  font-weight: 700;
  opacity: 1;
}

.create-memory-post-title:-ms-input-placeholder {
  color: #fff;
  font-weight: 700;
  font-family: RiftSoft-Bold;
}

.create-memory-post-title::-ms-input-placeholder {
  color: #fff;
  font-weight: 700;
  font-family: RiftSoft-Bold;
}

.create-memory-various-icons img {
  height: 70px;
  width: 70px;
  object-fit: contain;
  padding: 10px;
  text-align: center;
  align-items: center;
}

.create-memory-various-icons {
  width: max-content;
  margin: auto;
}

img.create-memory-footer-shadow {
  transform: rotate(180deg);
  position: fixed;
  bottom: 145px;
  margin-left: -15px;
  z-index: 999;
  height: 25px;
  width: 100%;
}

img.memory-settings-footer-shadow {
  transform: rotate(180deg);
  position: fixed;
  bottom: 74px;
  margin-left: -15px;
  z-index: 999;
  height: 25px;
}

img.remove-link-btn {
  height: 15px;
  width: 15px;
  object-fit: contain;
  position: absolute;
  right: 25px;
  margin-top: 5px;
}

.create-memory-next-button {
  background: #8cbf89;
  color: #fff;
  padding: 0px 15px 0px 15px;
  border-radius: 20px;
  font-family: RiftSoft-Bold;
  font-weight: bold;
  font-size: 25px;
  min-width: 110px;
  text-align: center;
  text-transform: uppercase;
}

.disabled-create-memory-next-button {
  background: #8cbf89;
  color: #fff;
  padding: 0px 15px 0px 15px;
  border-radius: 20px;
  font-family: RiftSoft-Bold;
  font-weight: bold;
  font-size: 25px;
  min-width: 110px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.6;
}

.memory-edit-location-time img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  margin-right: 10px;
}

.memory-location-time-input-field {
  border: none;
  font-size: 17px;
  font-family: Urbane-Medium;
  color: #707070;
}

.collaborators-invite-more-text p {
  color: #707070;
  font-family: Urbane-Medium;
  margin-bottom: 0px;
}

.memory-settings-custom-checkbox {
  width: max-content;
  border: 2px solid #00000029;
  border-radius: 15px;
  max-height: 24px;
}

.memory-settings-custom-checkbox-grey {
  background: #b3b3b3;
  padding: 10px;
  border-radius: 15px;
  margin-right: 20px;
}

.memory-settings-custom-checkbox-orange {
  background: #f19a7e;
  padding: 10px;
  border-radius: 15px;
  margin-left: 20px;
}

#auto-approval {
  color: #f19a7e;
}

.memory-collaborators-invite-more {
  min-height: 113px;
}

.collaborators-invite-more-text span {
  text-transform: uppercase;
  font-family: RiftSoft-Bold;
  color: #c2bcaf;
}

.create-memory-enter-details-scetion hr {
  /* margin-top: 0;
  margin-bottom: 0; */
  width: 94%;
  border-top: 2px solid #e6e6e6;
}

.invite-users-header {
  position: fixed;
  background: #fff;
  top: 0;
  z-index: 999;
  width: 100%;
  margin-left: -15px;
}

.search-all-invite-user {
  padding: 10px;
  display: flex;
  flex-direction: row;
}

img.invite-user-back-arrow {
  margin-right: 10px;
  object-fit: contain;
}

.search-invite-user-input-field {
  width: 100%;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  font-size: 15px;
  padding: 5px;
}

.search-friends-followers-following {
  padding: 5px 15px;
}

.search-option {
  text-align: center;
}

.search-option p {
  margin-bottom: 0;
  font-size: 14px;
  font-family: RiftSoft-Bold;
  color: #c2bcaf;
  text-align: center;
}

.selected-search-option p {
  margin-bottom: 0;
  font-size: 14px;
  font-family: RiftSoft-Bold;
  color: #f19a7e;
  border-bottom: 2px Solid #f19a7e;
  text-align: center;
}

img.clear-invite-input-field {
  position: absolute;
  right: 17px;
  height: 20px;
  width: 20px;
  object-fit: contain;
  top: 16px;
}

.invite-users-section {
  margin-top: 90px;
  margin-bottom: 80px;
  align-items: center;
  padding: 10px 0px;
}

.invite-user-icon {
  padding-right: 0px;
  text-align: center;
}

.invite-user-icon img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  border-radius: 25px;
}

p.no-paths-account {
  color: #707070;
  font-family: Urbane-Medium;
  width: 100%;
}

p.invite-user-paths-username {
  color: #f19a7e;
  text-transform: uppercase;
  font-family: RiftSoft-Bold;
  font-weight: 700;
  margin-bottom: 0;
}

p.invite-user-full-name {
  color: #492409;
  text-transform: capitalize;
  font-family: Urbane-Medium;
  font-size: 10px;
  margin-bottom: 0;
}

.invite-section-btn {
  background: #8cbf89;
  color: #fff;
  padding: 0px 10px 0px 10px;
  border-radius: 20px;
  font-family: Urbane-Medium;
  font-weight: bold;
  font-size: 12px;
  min-width: 75px;
  text-align: center;
  text-transform: uppercase;
}

.invite-user-btn {
  display: flex;
}

.qr-confirm-entry-screen {
  margin-left: -15px;
  margin-right: -15px;
}

.qr-confirm-entry-header {
  background: #f2f0ea;
  padding: 30px 0px;
}

.qr-confirm-entry-header h2 {
  color: #c2bcaf;
  text-transform: uppercase;
  font-family: RiftSoft-Bold;
  text-align: center;
}

.qr-confirm-entry-header p {
  color: #707070;
  text-align: center;
  font-family: Urbane-Medium;
  margin-bottom: 0px;
}

.qr-confirm-entry-section .collab-tab-section {
  background: url(../images/icons/wooden-background.png);
  background-size: cover;
  height: 500px;
}

.qr-confirm-entry-section .popup-qr-code-header {
  padding: 10px 0px;
  transform: scale(0.885) rotate(-5deg) translate(0px, 0px);
  box-shadow: 0 0 15px #ccc;
  margin: auto;
  max-width: 300px;
  min-width: 200px;
  width: 100%;
}

.go-get-the-app-section {
  margin-left: -15px;
  margin-right: -15px;
  padding: 60px 20px;
  text-align: center;
}

.go-get-the-app-section h2 {
  color: #f19a7e;
  font-family: RiftSoft-Bold;
}

.go-get-the-app-section p {
  margin-bottom: 0;
  color: #b3b3b3;
  font-family: Urbane-Medium;
  font-size: 20px;
}

.get-the-app-icons {
  display: inline-block;
  padding: 20px 0px;
}

.get-the-app-icons img {
  height: 30px;
  width: 80px;
  object-fit: contain;
}

.go-get-the-app-btn {
  position: fixed;
  bottom: 15px;
  margin-left: -15px;
  width: 100%;
}

.see-new-post-instead-button {
  background: #8cbf89;
  color: #fff;
  padding: 0px 15px 0px 15px;
  border-radius: 20px;
  font-family: RiftSoft-Bold;
  font-weight: bold;
  font-size: 25px;
  min-width: 90%;
  text-align: center;
  text-transform: uppercase;
}

.choose-carousel-body {
  padding: 10px;
  text-align: center;
}

.choose-carousel-body p {
  font-family: Urbane-Medium;
  font-size: 16px;
  margin-bottom: 0px;
}

.choose-carousel-body hr {
  border: 1px solid #707070;
  width: 95%;
}

.choose-carousel-body label {
  margin-bottom: 0px;
}

.close-carousel-overlay-btn {
  background: #f19a7e;
  color: #fff;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-family: Urbane-Medium;
  font-weight: bold;
  font-size: 16px;
  min-width: 75px;
  text-align: center;
  width: 95%;
}

.max-video-length-overlay {
  text-align: left;
}

p.one-minute-max-length {
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 10px;
}

.max-video-length-overlay-footer {
  font-weight: 700;
  text-align: right;
  padding: 10px 0px;
  color: #8cbf89;
  font-size: 15px;
}

.feed-page-desktop-margin {
  margin: 60px 0;
}

.entry-page-desktop-margin {
  /* margin: 70px -15px; */
  margin-top: 70px;
}

ul.nav.tabs.group.nav-pills.collabtab-list.create-memory-post-navigation
  li:first-child
  a {
  border-top-left-radius: 20px !important;
}

img.single-carousel-image {
  width: 100% !important;
  height: 200px !important;
  display: inline-block !important;
  margin: auto;
  padding: 20px 20px 0px 20px;
}

img.single-carousel-remove-icon {
  height: 10px !important;
  width: 10px !important;
  object-fit: contain !important;
  position: relative;
  top: 0px;
  float: right;
  right: 15px;
}

.slick-dots li button:before {
  font-size: 15px !important;
}

.slick-dots li {
  width: 15px !important;
  height: 15px !important;
}

.carousel-slider {
  margin-bottom: 30px;
}

.create-memory-custom-checkbox {
  width: max-content;
  border: 2px solid #00000029;
  border-radius: 25px;
  max-height: 34px;
  margin-left: 75px;
}

.custom-checkbox-grey {
  background: #b3b3b3;
  padding: 15px;
  border-radius: 15px;
  margin-right: 30px;
}

.custom-checkbox-orange {
  background: #f19a7e;
  padding: 15px;
  border-radius: 15px;
  margin-left: 30px;
}

.create-memory-footer-icons {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  z-index: 999;
}

.create-memory-footer {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.create-memory-footer span {
  color: #b3b3b3;
  font-size: 25px;
  font-family: Urbane-Medium;
}

.make-memory-private {
  display: flex;
}

.create-memory-carousel-description-section img {
  height: 40px;
  width: 40px;
  object-fit: contain;
  margin-bottom: 6px;
}

textarea.create-memory-description-section {
  width: -webkit-fill-available;
  height: 180px;
  border-radius: 10px;
  resize: none;
  font-family: Urbane-Medium;
  color: #c2bcaf;
  font-weight: 700;
}

.entry-paths-logo img {
  max-width: 170px;
}

.entry-footer {
  padding: 50px 15px 10px 15px;
}

.entry-footer-section {
  display: flex;
  flex-direction: row;
}

.entry-paths-logo {
  padding-right: 20px;
}

img.join-collab-tab {
  position: absolute;
  top: -40px;
  right: 10%;
  min-width: 118px;
  z-index: 99;
}

.no-padding {
  padding: 0 !important;
  margin: 0px 0px 0px 0px !important;
}

.container {
  overflow: hidden;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.path-slope .col-path-1 {
  height: 60px;
  transform: skewY(4.5deg);
  margin-bottom: -21px;
  background-color: #ffffff;
  overflow: hidden;
}

.path-slope .col-path-2 {
  height: 60px;
  transform: skewY(-4.5deg);
  margin-bottom: -21px;
  background-color: #ffffff;
  overflow: hidden;
}

.path-slope .col-path-1:before {
  box-shadow: 0 5px 20px rgba(2, 2, 2, 0.85);
  content: "";
  width: 140%;
  height: 10px;
  top: -15px;
  position: absolute;
  left: 0%;
  transform: skewY(2.5deg);
}

.path-slope .col-path-2:before {
  box-shadow: 0 5px 20px rgba(2, 2, 2, 0.85);
  content: "";
  width: 140%;
  height: 10px;
  top: -15px;
  position: absolute;
  right: 0%;
  transform: skewY(-2.5deg);
}

.buttonwrapper button {
  margin-left: -10%;
  border: none;
  border-radius: 25px;
  height: 42px;
  margin-bottom: 1px;
  color: #ffffff;
  background-color: #e95523;
}

.buttonwrapper input#ip1 {
  border-radius: 25px;
  background: #f5b8a4;
  padding: 20px;
  border: 1px solid #ca695f;
  width: 314px;
  height: 10px;
  outline: none;
}

.buttonwrapper {
  margin-right: -37px;
}

.hidden-collab-tag-print {
  overflow: hidden;
  height: 0;
}

button.btn.path-app-icon {
  background-color: #e95523;
  color: #ffffff;
  border: 2px solid coral;
}

/*Carousel*/
.post-container {
  width: 100%;
  height: 400px;
  display: block;
  position: relative;
  top: 0;
  bottom: 0;
  overflow: hidden;
  max-height: 100%;
}

.feed-post-carousel {
  width: 100%;
  /* height: 100%; */
  cursor: pointer;
  position: relative;
}

.feed-post-carousel-description {
  background-color: #fff;
  margin-top: -6px;
}

.feed-new-post-carousel-description {
  background-color: #ffffff;
  margin-top: -1px;
}

.post-provider {
  position: absolute;
  top: 10px;
  left: 5px;
  background: rgba(0, 0, 0, 0.4);
  height: 40px;
  width: 40px;
  border-radius: 30px;
  padding: 5px;
  color: #fff;
  font-size: 20px;
  z-index: 1;
  font-weight: 700;
}

.metadata-link-container {
  text-align: right;
  padding: 15px;
}

.metadata-link-container .metadata-link-title {
  border-right: 1px solid #585858;
  color: #585858;
  padding: 5px 10px;
  margin-right: 5px;
}

.metadata-link-container .metadata-link-image {
  max-width: 60px;
  cursor: pointer;
}

.metalink-dialog-container {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}

.metalink-dialog-container .link-item {
  display: flex;
  align-items: center;
  word-break: break-word;
  margin-bottom: 10px;
  justify-content: space-between;
  cursor: pointer;
}

.metalink-dialog-container .link-item img {
  max-width: 30px;
  margin-right: 10px;
}
.metalink-dialog-container .metadata-external-link {
  flex: 1;
  margin-left: 15px;
  font-size: 18px;
}

.link-item-wrapper {
  padding: 20px;
  margin: 0;
}

.link-item-container {
  border: 1px solid #c2c2c2;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgb(194, 194, 194);
  margin-bottom: 25px;
  background-image: linear-gradient(
    to top,
    #f7efdf,
    #f8f1e4,
    #faf3e8,
    #fbf6ed,
    #fcf8f2,
    #fdf9f5,
    #fefbf7,
    #fffcfa,
    #fffdfc,
    #fffdfe,
    #fffeff,
    #ffffff
  );
}

.link-item-container .title {
  margin-top: 20px;
}

.no-item-container {
  background: url("../images/logo/text-only-post.jpg");
  background-size: 100% 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #333;
}

.custom-dialog.blogger-dialog .modal-content button.close {
  top: 13px;
}

.post-overlay {
  padding: 0 10px;
  position: relative;
  min-height: 200px;
  cursor: pointer;
  color: #585858;
  word-break: break-word;
  overflow: auto;
}

.post-overlay:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  z-index: 999;
}

.post-overlay .separator {
  border: none;
}

.feed-container {
  min-width: 50%;
  min-height: 50%;
  width: auto;
  height: auto;

  /* Center the video */
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  object-fit: contain;
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  height: 100%;
  padding: 0 !important;
}

.video-react .video-react-control-bar {
  top: calc(100% - 30px);
}

.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.feed-container-video {
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  max-height: 100%;
  /* Center the video */
  position: relative;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  max-height: 500px;
  border-radius: 0px 0px 20px 20px;
  overflow: hidden;
}

.carousel .slide {
  background: unset !important;
}

.carousel.carousel-slider .control-arrow {
  top: 50% !important;
  border-radius: 100%;
  bottom: unset !important;
  padding: 0px 10px !important;
}

.header-time-para {
  color: #ffffff;
  padding-bottom: 5px !important;
}
.clr {
  color: #ffffff;
}

.icon-style {
  text-align: center;
}

.icon-style i {
  font-size: x-large;
}

.loader span {
  font-size: 30px;
  color: #585858;
  z-index: 2;
  position: relative;
}

.btn-loader {
  font-size: x-large;
  color: #ffffff;
  margin: -13px;
}

.comment-wrapper .panel-body {
  max-height: 650px;
  overflow: auto;
}
/*Comment Section Styling start*/
.comment-wrapper .media-list .media .comment-user-img {
  width: 30px;
  height: 30px;
  border: 2px solid #e5e7e8;
  margin-right: 10px;
  object-fit: cover;
}

.username-validation-requirements {
  margin-top: 10px;
  padding-left: 15px;
  font-size: 11px;
  color: #707070;
  font-family: Urbane-Medium;
  /* display: none; */
}

.username-validations {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#username-no-space,
#username-no-special-char,
#username-must-unique,
#username-characters,
#password-characters,
#password-number,
#password-special-character {
  visibility: hidden;
}

.permission-denied-paths-logo {
  height: auto;
  width: 50%;
}

.permission-denied-header-text {
  font-family: RiftSoft-Bold;
  color: #f19a7e;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  padding: 50px 0px;
}

.permission-denied-footer-text {
  color: #c2bcaf;
  font-family: Urbane-Medium;
  font-size: 15px;
  padding: 20px 0px;
}

.permission-denied-signin-label {
  padding-top: 10px;
  color: #c2bcaf;
  font-family: Urbane-Medium;
}

.permission-denied-section {
  text-align: center;
  margin-top: 70px;
  padding: 20px;
}

.permission-denied-feed-page-redirect {
  font-family: Urbane-Medium;
  font-size: 15px;
  color: #c2bcaf;
}

.permission-denied-post-owner-name {
  text-transform: uppercase;
}

.error-page-header {
  padding: 15px;
  text-align: center;
  font-size: 55px;
  font-weight: bold;
  color: #f19a7e;
  text-transform: uppercase;
  font-family: RiftSoft-Bold;
  margin-top: 50px;
}

.error-page-section {
  color: #707070;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  font-family: Urbane-Medium;
  padding-bottom: 20px;
}

.error-page-section-links {
  padding-top: 20px;
  color: #8cbf89;
  font-weight: 700;
}

.error-page-section-links p:hover,
span:hover {
  cursor: pointer;
}

.error-page-section-links div {
  margin-bottom: 10px;
}

.media-list {
  padding-left: 30px;
  padding-right: 30px;
  margin: 0;
}

.form-comment-area:focus {
  background-color: #ffffff;
  border-color: #cecece !important;
  box-shadow: none !important;
}

.back-arrow-link {
  text-decoration: none;
  background-color: transparent;
  color: #c2bcaf;
  cursor: pointer;
  float: left;
  flex: 1 auto;
  margin-bottom: 4px;
}

.back-arrow-link:hover {
  text-decoration: none !important;
  background-color: transparent;
}

a.tip:hover {
  text-decoration: none;
}

.lab span i {
  padding: 3px;
}

.inputfile {
  display: none;
}

.received_msg {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.received_withd_msg pre {
  color: #492409;
  font-size: 14px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 5px;
  font-family: Urbane-Medium;
  white-space: break-spaces;
  word-break: break-word;
}

.received_withd_msg {
  width: 100%;
}

.profile-pic {
  position: relative;
}

.profile-pic img {
  float: right;
  height: 140px;
  max-width: 25%;
  margin-bottom: 10px;
}

.profile-pic:hover .edit {
  display: block;
  cursor: pointer;
}

.edit {
  position: absolute;
  right: 5px;
  top: 5px;
}

.comment-image {
  height: auto;
  width: auto;
  max-width: 200px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.user-comment-box-image {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.user-comment-box-link {
  cursor: pointer;
}
/*Comment Section Styling end*/

/*blank post feed empty box start*/
.nopost-container {
  width: 100%;
  height: 400px;
  text-align: center;
  justify-content: center;
  background-color: #f2f2f2;
  resize: none;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  font-size: 32px;
  font-family: RiftSoft-Bold;
  padding: 0px 20px 0px 20px;
}
/*blank post feed empty box end*/

@media (max-width: 1263px) {
  .navbar-root {
    margin-top: 5px;
  }

  .login-sign-up-footer {
    padding-top: 190px;
  }

  .login-sign-up-footer img {
    height: 45px;
    width: 110px;
  }
}

@media (max-width: 1020px) {
  .user-profile-qrCodeContainer {
    position: relative;
    margin: 0px;
  }
}

@media (max-width: 1059px) {
  .feed-post-footer-buttons img {
    height: 75px;
    width: 200px;
    object-fit: contain;
  }

  .user-profile-collab-tag-caption {
    color: #f19a7e;
    font-family: Urbane-Medium;
    max-width: 60%;
    margin: auto;
    text-align: center;
    font-size: 9px;
    padding-bottom: 5px;
  }
}

@media (max-width: 963px) {
  .navbar-root {
    margin-top: 10px;
  }

  .user-profile-collab-tag-caption {
    color: #f19a7e;
    font-family: Urbane-Medium;
    max-width: 60%;
    margin: auto;
    text-align: center;
    font-size: 8px;
    padding-bottom: 10px;
  }

  .feed-post-footer-buttons img {
    height: 60px;
    width: 160px;
    object-fit: contain;
  }

  p.feed-post-footer-head {
    color: #f19a7e;
    font-size: 38px;
    font-family: RiftSoft-Bold;
    font-weight: 700;
    line-height: 45px;
    text-transform: uppercase;
  }
}

@media (max-width: 801px) {
  .header-para {
    font-size: 16px;
    color: #ffffff !important;
  }

  .buttonwrapper input#ip1 {
    border-radius: 25px;
    background: #f5b8a4;
    padding: 20px;
    border: 1px solid #ca695f;
    width: 212px;
    height: 10px;
    outline: none;
    margin-top: 25px;
  }
}

@media (max-width: 1684px) {
  .navbar-root {
    margin-top: 2px;
  }
}

@media (max-width: 768px) {
  .multi-qr-code-uri {
    width: 200px;
    height: 165px;
    object-fit: contain;
  }

  .ship-address-icon-uri {
    padding: 10px 10px 10px 0px;
    object-fit: contain;
    height: 50px;
    width: 60px;
  }

  p.get-collab-tags-text-uri {
    padding: 15px 15px 10px 0px;
    color: #c2bcaf;
    font-weight: bold;
    font-size: 19px;
  }

  .collab-tags-shiping-header-uri {
    color: #707070;
    padding-left: 5px;
    font-size: 18px;
    text-align: left;
    padding-right: 8px;
  }

  .order-detail-title-uri {
    font-size: 15px;
    font-weight: bold;
    color: #f19a7e;
    padding: 0px 0px 0px 40px;
  }
}

.clearboth {
  clear: both;
  margin-top: 0.4rem;
}

.margintop {
  margin-top: 6px;
}
html {
  scroll-behavior: auto;
}

.feed-post {
  /* margin: -15px 0px 0px; */
  background-color: #fbf9f6;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px;
  width: 100%;
}

.col-md-3.paths-user-profile {
  padding-top: 20px;
  display: block;
  margin-bottom: 40px;
}

.sigle-feed-post {
  padding: 0px;
  background-color: #fbf9f6;
  /* border-top-left-radius: 20px; */
  /* border-top-right-radius: 20px; */
  /* border-bottom-left-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
}

.feed-post-footer pre {
  white-space: pre-wrap;
  font-family: Arial, Helvetica, sans-serif;
  color: #585858;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 !important;
}
nav {
  display: block;
}

.tabs {
  margin-bottom: -4px;
}
.alert-danger {
  border-radius: 25px;
}

.btn-color {
  color: #ef3c23;
}
.forgot-password-link {
  flex: 1;
  text-align: center;
  margin-left: 20px;
  /* padding-right: 20px; */
  margin-bottom: 10px;
  font-family: Urbane-Medium;
}

.signup-link-text-center {
  flex: 1;
  text-align: center;
  font-family: Urbane-Medium;
}

.login-overlay {
  background: rgba(0, 0, 0, 0.08);
  color: #f26739;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

.login-options {
  margin: 0px;
  /* font-family: 'urbane-rounded'; */
  background-color: #fbf9f6;
}

.login-options .line h2 {
  font-family: RiftSoft-Bold;
  margin-top: 20px;
}

.payment-failure-message {
  display: block;
  color: #f19a7e;
  text-align: left;
  font-size: 11px;
}

.review-purchase-btn {
  background-color: #8cbf89;
  border-radius: 10px !important;
  color: #fff;
  border: solid #8cbf89;
  flex: 2;
  padding: 0px 86px;
  font-size: 20px;
  font-weight: bold;
}

.review-purchase-disabled-btn {
  background-color: #8cbf89;
  border-radius: 10px !important;
  color: #fff;
  border: solid #8cbf89;
  flex: 2;
  padding: 0px 86px;
  font-size: 20px;
  font-weight: bold;
  opacity: 0.5;
}

.make-payment-btn {
  background-color: #8cbf89;
  border-radius: 10px !important;
  color: #fff;
  border: solid #8cbf89;
  flex: 2;
  padding: 0px 105px;
  font-size: 20px;
  font-weight: bold;
}

.collab-tags-header-text {
  margin-left: -15px;
}

.collab-tags-done-content {
  color: #707070;
  font-size: 17px;
  text-align: center;
  padding-bottom: 20px;
  font-family: Urbane-Medium;
}

.purchase-btn {
  background-color: #8cbf89;
  border-radius: 10px !important;
  color: #fff;
  border: solid #8cbf89;
  flex: 2;
  padding: 0px 75px;
  font-size: 19px;
  font-family: RiftSoft-Bold;
  font-weight: bold;
}

.order-done-btn {
  background-color: #f19a7e;
  border-radius: 10px !important;
  color: #fff;
  border: solid #f19a7e;
  flex: 2;
  padding: 0px 175px;
  font-size: 20px;
  font-weight: bold;
}

.edit-order {
  float: right;
  padding: 5px 17px 40px 0px;
  font-size: 15px;
  font-weight: bold;
  color: #b3b3b3;
}

.edit-order-uri {
  font-size: 15px;
  font-weight: bold;
  color: #b3b3b3;
  font-family: Urbane-Medium;
  /* margin-right: -300px; */
}

.edit-order-uri:hover {
  cursor: pointer;
}

.edit-order:hover {
  cursor: pointer;
}

.login-btn {
  background-color: #8cbf89;
  border-radius: 10px !important;
  color: #fff;
  border: solid #8cbf89;
  flex: 2;
  padding: 0px;
  font-size: 25px;
  font-family: RiftSoft-Bold;
}

.login-btn .btn-loader {
  font-size: 28px;
}

.signup-link:hover,
.forgot-password-link:hover,
.login-btn:hover,
.signup-btn,
.signup-close {
  cursor: pointer;
}

.login-options-left {
  padding: 0 50px 0 0;
}
.login-options-right {
  padding: 0 0 0 50px;
}

.login-btn-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.paths-login-container input {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid;
  background-color: #ffffff;
  padding: 10px;
  color: #585858;
  border-color: #cecece;
}

.paths-login-container button {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 8px;
}

.forgot-password .row {
  max-width: 520px;
  margin: 0 50px;
}

.forgot-password h3 {
  margin: 0 auto 20px;
  /* font-family: 'rift-soft'; */
  color: #f26739;
}

.forgot-password .login-btn-container {
  max-width: 100px;
  margin: 0 auto;
}

.forgot-password input {
  color: #585858;
  border-color: #cecece;
}

.forgot-password .btn-loader {
  font-size: 24px;
  padding: 5px 0;
}

.forgot-password a.login-link {
  color: #f26739;
  cursor: pointer;
  padding-right: 5px;
  font-family: Urbane-Medium;
}

.line {
  /* border-left: 1px solid #cecece;
  padding: 50px 190px; */
  padding: 30px 20px;
  max-width: 400px;
  margin: auto;
}

.input-password-hide-show {
  position: relative;
}

.row-style:after {
  content: "";
  display: table;
  clear: both;
}
.btn-social-media {
  /* font-size: 14px; */
  border-radius: 40px;
  border: 1px solid;
  margin-left: 10px;
}

.text-header {
  color: #c2bcaf;
  font-weight: bold !important;
  /* font-family: 'rift-soft' !important; */
  font-size: 25px !important;
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.social-login-container {
  margin: 40px 0;
}

/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  padding: 0 10px;
  border: none;
  text-align: left;
  line-height: 34px;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
  max-width: 230px;
  display: block;
  margin: 0 auto;
}

.login-icon {
  margin-right: 5px;
  min-width: 15px;
  font-size: 18px;
}

.loginBtn .social-login-label {
  border-left: 1px solid;
  padding-left: 10px;
}

@media (max-width: 1200px) {
  .loginBtn {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .loginBtn {
    font-size: 16px;
  }

  .user-profile-qr-code-container {
    margin-top: -30px;
  }
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.facebook-login-btn {
  padding-left: 35px;
  padding-right: 0;
}
/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
  background: #dd4b39;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}

/* Apple */

.loginBtn--apple {
  background-color: #000;
  background-image: linear-gradient(#000, #000);
  text-shadow: 0 -1px 0 #000;
}

.loginBtn--apple:hover,
.loginBtn--apple:focus {
  background: #000000d9;
}

/*  for signup  */

.popup_inner {
  background: transparent;
  position: fixed;
  left: 0;
  right: 0;
  top: 0%;
  border: 2px solid #cecece;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow: auto;
}
.signup-form {
  width: 70%;
  background: #cecece;
  text-align: center;
  border-radius: 10px;
  position: relative;
  padding: 30px 10px;
  color: #f26739;
}

.signup-container input {
  margin-right: 0;
  /* margin-left: 16px; */
  border-color: #b3b3b3;
}

.social-media-modal {
  margin-right: 0;
  margin-left: 0;
  border-color: #f26739;
}

.signup-close {
  position: absolute;
  right: 17px;
  top: 3px;
  /* margin-top: -26px; */
}
.btn-signup-wrapper {
  display: flex;
  justify-content: space-between;
}
.btn-signup-wrapper label {
  flex: 1 auto;
  max-width: 75%;
  text-align: left;
  margin-right: 10px;
  padding-left: 50px;
}

.external-link-container,
.external-link-container i {
  margin: 0 5px;
}

.external-link-container a {
  color: #f26739;
}

label.term-condition-wrapper {
  font-size: 14px;
}
.input-btn {
  /* text-align: center; */
  width: 50;
  /* margin-left: 5px; */
  margin-right: 0px;
  /* margin: 10px; */
}

.name-container {
  position: relative;
  margin-bottom: 0;
}

.name-container span {
  position: absolute;
  top: 3px;
  right: 20px;
  font-size: 12px;
}

.signup-btn {
  border-radius: 10px !important;
  color: #fff !important;
  align-items: center;
  align-self: center;
  padding: 5px 10px;
  /* width: 119px;
  height: 35px; */
  font-family: RiftSoft-Bold;
  font-weight: bold;
  font-size: 20px;
}

.signup-required-text {
  color: #b3b3b3;
  position: absolute;
  top: 235px;
  right: 25px;
  font-size: 10px;
}

.signup-option {
  display: flex;
}

.signup-box {
  flex: 1;
  text-align: center;
  margin-top: 4rem;
}

.custom-dialog .paths-login-container input {
  padding: 5px 10px 5px 10px;
  margin-bottom: 0px;
}

.phone-container {
  position: relative;
  margin-bottom: 0px;
}

.paths-phone .PhoneInputCountry {
  border: solid 1px #b3b3b3;
  width: 15%;
  border-radius: 10px;
  justify-content: center;
}

.paths-phone .PhoneInputCountrySelect {
  border: solid 5px gray;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}

.paths-phone .PhoneInputCountryIcon--border {
  box-shadow: none;
}

.user-icon-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  min-height: 150px;
}

.paths-phone .PhoneInputCountrySelectArrow {
  color: #f19a7e;
  font-size: 20px;
  font-weight: bold;
  opacity: 1;
  border-right-width: 3px;
  border-bottom-width: 3px;
  width: 7px;
  height: 7px;
  margin-left: 15px;
}

.paths-phone input.PhoneInputInput {
  padding-left: 70px;
  height: 30px;
}

.phone-code {
  position: absolute;
  top: 0px;
  left: calc(20% + -13px);
  background: #cecece;
  padding: 3px 10px;
  border: solid 1px #b3b3b3;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 30px;
  min-width: 60px;
  text-align: center;
  color: #585858;
  border-right: none;
}

@media (max-width: 990px) {
  .signup-form {
    width: 100%;
  }
  .signup-btn {
    margin: auto;
  }
  .btn-signup-wrapper label {
    max-width: 100%;
    margin-bottom: 15px;
  }
  .btn-signup-wrapper {
    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {
  .privacy-wrapper {
    margin-left: 0;
  }
  .btn-signup-wrapper label {
    margin-right: 0px;
    padding-left: 40px;
  }
  .popup_inner {
    padding: 10px;
  }
  .footer-marketplace-icons img {
    height: 30px;
    width: 75px;
    object-fit: contain;
  }
  p.footer-colaborate-detail {
    color: #ed977c;
    font-family: Urbane-Medium;
    font-size: 13px;
  }
}

@media (max-width: 560px) {
  .logout {
    font-size: 15px !important;
    font-weight: 700 !important;
    cursor: pointer !important;
    position: fixed !important;
    right: 80px !important;
    top: 10px !important;
    text-align: right !important;
    color: #ef3c23 !important;
    z-index: 999999 !important;
  }
}

@media (max-width: 650px) {
  .header-section-paths-logo img {
    max-width: 130px;
  }

  .entry-paths-logo img {
    max-width: 130px;
  }

  .invite-header {
    text-align: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background: #fff;
    display: block;
    margin-top: 70px !important;
  }

  img.feed-header-section-dividor {
    position: fixed;
    top: 54px !important;
    height: 15px;
    width: 100%;
    z-index: 999;
  }
}

@media (max-width: 510px) {
  img.welcome-overlay-paths-logo {
    height: 65px;
    width: 300px;
    object-fit: contain;
  }

  .welcome-overlay-welcome-message h1 {
    color: #f19a7e;
    text-transform: uppercase;
    font-weight: 700;
    font-family: RiftSoft-Bold;
    font-size: 40px;
    padding-top: 40px;
  }

  .welcome-overlay-welcome-message p {
    font-family: Urbane-Medium;
    color: #492409;
    font-size: 14px;
  }

  .welcome-overlay-welcome-message img {
    height: 75px;
    width: 60px;
    object-fit: contain;
  }

  .welcome-overlay-further-guide p {
    padding: 20px 0px 20px 0px;
  }

  .review-purchase-btn {
    background-color: #8cbf89;
    border-radius: 10px !important;
    color: #fff;
    border: solid #8cbf89;
    flex: 2;
    padding: 0px 104px;
    font-size: 19px;
    font-weight: bold;
  }

  .review-purchase-disabled-btn {
    background-color: #8cbf89;
    border-radius: 10px !important;
    color: #fff;
    border: solid #8cbf89;
    flex: 2;
    padding: 0px 86px;
    font-size: 20px;
    font-weight: bold;
    opacity: 0.5;
  }

  .purchase-btn {
    background-color: #8cbf89;
    border-radius: 10px !important;
    color: #fff;
    border: solid #8cbf89;
    flex: 2;
    padding: 0px 89px;
    font-size: 19px;
    font-weight: bold;
  }
}

::placeholder {
  color: #b3b3b3;
}
.error {
  text-align: left;
  display: block;
  margin-left: 10px;
  margin-right: 10px;
  color: #f19a7e;
}

.error-showLogin {
  cursor: pointer;
}

.logout {
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  position: fixed;
  right: 2%;
  top: 10px;
  text-align: right;
  color: #ef3c23;
  z-index: 999999;
  font-family: RiftSoft-Bold;
}

.logout-icon {
  font-size: 24px;
  vertical-align: middle;
}

.logout-color {
  margin-left: 10px;
}

@media (max-width: 575px) {
  .edit-user-form .error {
    margin-left: 0px;
  }

  .error .paths-web-error {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  span.text-red.icon-style.logout-color {
    display: block;
  }
}

/* for logout alert */
.logoutalert {
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.closebttn {
  margin-left: 15px;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/***modal**/
.modal-open .modal {
  overflow: visible;
}

.modal-body {
  padding: 0;
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 767px) {
  .post-header-text-alignment {
    flex: 1;
  }
}

.radio-error {
  margin-top: 10px;
}

.btn-signup-wrapper.custom-radio .custom-control-label::before {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background: #fff;
  border: 1px solid #f26739;
  position: absolute;
}

.btn-signup-wrapper .custom-control-label::after {
  left: 5px;
  top: 9px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.btn-signup-wrapper.custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-image: none !important;
  background: #4aab32c2;
}

.btn-signup-wrapper input {
  width: 31px;
  height: 31px;
}

.feed-social-icon-post {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.feed-social-icon-post li {
  margin-left: 0;
  display: block;
  margin-right: 0;
  float: right;
  transition: width 0.15s ease-in-out;
}

.feed-social-icon-post li div,
.feed-social-icon-post .icon-social {
  transition: width 0.15s ease-in-out;
  border-radius: 0;
  font-size: 22px;
  width: 35px;
  height: 40px;
  line-height: 31px;
}

.feed-social-icon li button.react-share__ShareButton {
  width: 100%;
  height: 100%;
}

.post-title {
  margin: 0;
  padding: 0px 25px;
  line-height: 30px;
  font-size: 20pt;
  font-weight: bold;
  font-family: RiftSoft-Bold;
  color: #ffffff;
  text-align: center;
  word-break: break-word;
}

.myspace-container .post-title {
  padding: 5px;
  text-align: left;
}

.myspace-container .post-title .myspace-logo {
  max-height: 35px;
  padding: 0 10px;
}

.post-user-name {
  margin: 0;
  padding: 0;
  font-size: 11pt;
  font-family: Urbane-Medium;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
  margin-top: -5px;
  margin-bottom: -5px;
}
.post-user-location {
  font-size: 9pt;
  font-family: Urbane-Medium;
  color: #676767;
}

.post-title-time {
  margin: 0px;
}

.tt-gj-wrapper {
  cursor: default;
}

.teachable-gratitude-container {
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 3px #b3b3b37a;
}

.link-list-container {
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 3px #b3b3b37a;
  margin-bottom: 20px;
}

.tt-gj-container {
  display: flex;
  padding: 10px;
  align-items: center;
}
.tt-gj-icon {
  width: 40px;
}

.teachable-gratitude-body {
  font-size: 14px;
  flex: 1;
  color: #707070;
  padding: 0px 25px 10px 25px;
  font-family: Urbane-Medium;
  display: flex;
  flex-wrap: wrap;
}

.link-list-body {
  font-size: 14px;
  flex: 1;
  color: #585858;
  padding: 0px 25px 10px 25px;
}

.teachable-gratitude-title {
  font-size: 16px;
  font-weight: bold;
  color: #f19a7e;
  padding: 5px 0px;
  font-family: RiftSoft-Bold;
  min-width: 100%;
  flex: 100%;
}

.box {
  height: 40px;
  width: 40px;
  border: #c2bcaf solid 1px;
  max-width: 40px;
  display: block;
  margin-right: 10px;
}

.box img {
  height: 40px;
  width: 40px;
}

.box-text {
  flex: 1;
  font-size: 12px;
}

.link-list-title {
  font-size: 16px;
  font-weight: bold;
  color: #f19a7e;
  padding: 5px 0px;
}

.tt-gj-text {
  padding: 0px 10px;
  font-family: RiftSoft-Bold;
  font-weight: bold;
  font-size: 15pt;
}

.tt-gj-text p {
  color: #c2bcaf;
  margin: 0px;
}

.post-comment .media-body {
  flex: 1;
  background: #fbf9f6;
  padding: 10px;
  border-radius: 6px;
  border: 1pt #c2bcaf75 solid;
  margin-bottom: 20px;
}

p.comment-head {
  text-align: center;
  color: #c2bcaf;
  font-weight: bold;
  font-size: 15pt;
  font-family: RiftSoft-Bold;
}

.terms-privacy-policy {
  padding: 0px 15px 0px 15px;
  font-size: 11px;
  color: #707070;
  font-family: Urbane-Medium;
}

.terms-privacy-policy span {
  cursor: default;
}

span.terms-of-use {
  text-decoration: underline;
}

span.terms-of-use:hover {
  cursor: pointer;
}

p.signup-pin-header {
  color: #f19a7e;
  font-family: Urbane-Medium;
  padding: 0px 25px 0px 25px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

p.pin-heading {
  text-align: center;
  color: #707070;
  margin-bottom: 0px;
  font-size: 12px;
  font-family: Urbane-Medium;
}

p.resend-pin-button {
  color: #b3b3b3;
  text-align: center;
  /* margin-top: -20px; */
  font-family: Urbane-Medium;
}

p.resend-pin-button:hover {
  cursor: pointer;
}

p.signup-done-header {
  color: #f19a7e;
  font-family: RiftSoft-Bold;
  padding: 0px 25px 0px 25px;
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;
  font-size: 29px;
  margin-top: 20px;
  font-weight: bold;
}

p.done-heading {
  text-align: center;
  color: #707070;
  margin-bottom: -5px;
  font-size: 9px;
  padding: 0px 80px;
  font-family: Urbane-Medium;
}

.done-signup-store-images {
  padding: 25px 20px 20px 20px;
  margin: auto;
  display: flex;
  justify-content: center;
}

.done-signup-store-images img {
  max-width: 50%;
}

.done-signup-images {
  height: 135px;
  width: 164px;
  padding: 20px 20px 20px 20px;
  /* margin-left: 125px; */
  margin: auto;
}

.done-signup-images img:hover {
  cursor: pointer;
}

.post-comment-box .form-comment-area {
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  cursor: text;
  border-radius: 25px !important;
  padding-left: 20px;
  flex: 1;
  color: #492409;
  font-family: Urbane-Medium;
}

.input-group-append.fileattachment {
  margin: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
}

.post-comment-box .fileattachment .lab {
  padding: 0px;
  border-radius: 40px;
  margin-bottom: 15px;
  color: #c2bcaf;
  justify-content: center;
  text-align: center;
}

.post-comment-box .fileattachment .lab .icon-style i {
  font-size: 30px;
}

.post-comment-box .fileattachment .btn-post {
  height: 30px;
  padding: 0px !important;
  margin-right: 5px;
  background: #f26739;
  border: 1px solid #f26739;
  border-radius: 40px;
  color: #ffffff;
  padding-right: 5px;
  padding-left: 1px;
  margin-top: 5px;
  justify-content: center;
  width: 110px;
}

@media (max-width: 767px) {
  .feed-page-desktop-margin {
    margin-top: 0px;
  }

  .entry-page-desktop-margin {
    margin-top: 0px;
  }

  p.user-profile-card-header-text-without-margin {
    font-size: 25px;
    font-weight: bold;
    color: #f19a7e;
    /* font-family: 'rift-soft'; */
    padding: 10px 10px 0px 10px;
    line-height: 25px;
  }

  .user-feed-post-footer {
    display: none;
  }

  .header-section-login-buttons-marketplace-icons {
    display: block;
    display: flex;
    justify-content: flex-end;
  }

  .logout {
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    position: fixed;
    right: 80px;
    top: 10px;
    text-align: right;
    color: #ef3c23;
    z-index: 999999;
  }

  .nopost-container {
    font-size: 22px;
  }

  p.post-place.user-profile-card {
    font-size: 8pt;
    color: #676767;
    margin-bottom: -5px;
    font-family: Urbane-Medium;
  }

  p.post-place.user-profile-card-without-margin {
    font-size: 10pt;
    margin-bottom: 0px;
    margin-top: -10px;
    color: #676767;
    font-family: Urbane-Medium;
  }

  .post-header-text.user-profile-card-high-resolution {
    min-height: 70px;
  }

  .download-collabtag-now {
    text-align: center;
    font-size: 30px;
    color: #8cbf89;
    font-family: Urbane-Medium;
  }

  .error-page-header {
    font-size: 9.5vw;
    /* font-family: RiftSoft-Bold; */
    margin-top: 60px;
  }

  .error-page-section {
    font-size: 4.5vw;
  }

  header.top-header .container {
    max-width: 100%;
  }

  .download-qr-link {
    display: none;
  }

  .high-reslution-collab-tag-review {
    padding: 0px;
  }

  .qr-code-header-without-margin {
    box-shadow: 0 0 15px #ccc;
    /* display: none; */
    min-height: 0px;
    max-width: 580px;
    margin-bottom: 35px;
  }

  .circle-without-margin {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 35px;
    margin-bottom: 11px !important;
    object-fit: cover;
  }

  img.add-me-icon.user-profile-card-without-margin {
    height: 50px;
    width: 50px;
  }

  p.post-title.user-profile-card-without-margin {
    margin: 0;
    padding: 0;
    font-size: 20pt;
    font-weight: bold;
    font-family: RiftSoft-Bold;
    color: #ffffff;
    text-align: center;
    word-break: break-word;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  span.post-user-name.user-profile-card-without-margin {
    margin: 0;
    padding: 0;
    font-size: 10pt;
    font-family: Urbane-Medium;
    text-transform: capitalize;
    color: #ffffff;
    text-align: center;
    margin-top: -5px;
    margin-bottom: 10px;
  }

  p.single-post-description.user-profile-card-without-margin {
    word-break: break-all;
    padding-top: 0px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 15px;
    padding-bottom: 10px;
    min-width: 100%;
    display: block;
    background-color: #ffffff;
  }

  .multi-qr-code-uri {
    width: 200px;
    height: 135px;
    object-fit: cover;
  }

  p.get-collab-tags-text-uri {
    padding: 15px 0px 10px 0px;
    color: #c2bcaf;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .collab-tags-header-uri {
    padding: 40px 5px;
  }

  .radio {
    margin: 0.5rem;
    flex: 1;
    min-width: 68px;
  }

  p.collab-details-first-uri {
    color: #8cbf89;
    font-size: 7px;
    margin-bottom: 0px;
  }

  p.collab-details-second {
    color: #8cbf89;
    font-size: 25px;
    font-weight: bold;
    margin-top: -10px;
  }

  p.collab-details-third-uri {
    color: #707070;
    font-size: 8px;
    margin-top: -20px;
    margin-bottom: 0px;
  }

  .radio label,
  .checkbox label {
    min-height: 24px;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0;
    padding-left: 0px;
  }

  p.choose-quantity-text-uri {
    color: #f19a7e;
    font-weight: bold;
    font-size: 10px;
  }

  .collab-tags-shiping-header-uri {
    color: #707070;
    padding-left: 5px;
    font-size: 13px;
    text-align: left;
    padding-right: 5px;
  }

  .ship-address-icon-uri {
    padding: 10px 0px 10px 0px;
    object-fit: contain;
    height: 45px;
    width: 50px;
  }

  .post-comment-box {
    padding-left: 10px;
    padding-right: 10px;
  }

  .order-detail-title-uri {
    font-size: 15px;
    font-weight: bold;
    color: #f19a7e;
  }

  .collab-tags-header-uri {
    margin-right: 0px;
    margin-left: 0px;
    background-color: #fbf9f6;
  }

  .comment-warning {
    padding: 0 10px;
  }

  .display-sharing-feed {
    display: none !important;
  }

  .anchor_style img {
    height: 35px !important;
    width: 35px !important;
    margin: unset;
    margin-bottom: 5px;
  }

  .margintop {
    margin-top: 3px;
  }
  .marker-icon i {
    font-size: 30px !important;
    margin-bottom: 10px;
  }

  .media-list {
    padding-left: 10px;
    padding-right: 10px;
  }

  .navbar-path li.active a {
    border-radius: 20px 20px 0 0 !important;
  }

  a.nav-link.anchor_style {
    /* padding-top: 10px; */
    padding: 10px 8px 8px 8px;
  }

  a.nav-link.icon-plus {
    /* padding-top: 10px; */
    padding: 10px 8px;
  }

  .navbar-path .tabs li:first-child a {
    border-top-left-radius: 0px !important;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    border-radius: 0;
  }

  a.btn.btn-headersection {
    padding: 10px 20px;
    font-size: 22px;
    border-radius: 10px;
    border-width: 2px;
  }

  .post-header-title span {
    font-size: 16px;
    font-family: RiftSoft-Bold;
  }

  .post-header-title p {
    font-size: 14px;
  }

  .invite-postheader p {
    margin-left: 0%;
    margin-right: 0%;
  }

  .icon-style-plus i {
    font-size: 35px;
  }

  .icon-plus i {
    font-size: 35px;
  }
}

.feed-post-footer {
  cursor: pointer;
  /* padding: 0px 50px; */
  padding: 15px;
}

.feed-post-footer-without-description {
  padding: 15px;
}

a.text-secondary {
  color: #f19a7e !important;
}

a.text-secondary:hover {
  color: #f19a7e !important;
}

.feed-post-footer .feed-text {
  display: contents;
  font-size: 16px;
  white-space: pre-wrap;
  overflow-x: hidden;
  text-align: left;
}
.icon-plus {
  z-index: 9 !important;
  padding: 8px !important;
}
.icon-style-plus {
  z-index: 9 !important;
}

.marginTop-slop {
  margin-top: 100px !important;
}

.header-background {
  background: #f79868;
}

.header-background p {
  margin-bottom: 0rem !important;
}

.post-comment hr {
  margin-left: 3%;
  margin-right: 3%;
}

.comment-owner {
  text-transform: capitalize;
  font-size: 12px;
  color: #f19a7e;
  margin-bottom: 5px;
  font-family: RiftSoft-Bold;
}

.comment-date {
  font-size: 10px;
  margin-bottom: 0;
}

.comment-slope .path-slope .col-path-1:before,
.comment-slope .path-slope .col-path-2:before {
  box-shadow: none !important;
}

.comment-slope .marginTop-slop {
  margin-top: 0px !important;
}

.comment-slope .path-slope .col-path-1 {
  border-bottom-left-radius: 30px;
}

.comment-slope .path-slope .col-path-2 {
  border-bottom-right-radius: 30px;
}

img.post-header-divider-img {
  display: none;
}

img.feed-post-header-shadow {
  height: 15px;
  width: 100%;
  object-fit: contain;
}

.invite-header .col-7 {
  margin-top: -0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #ffffff;
}

.invite-header .col-5.owner-detail.justify-content-center.align-items-sm-end {
  background-color: #ffffff;
  margin-top: -0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.invite-header .row img {
  background-color: #ffffff;
}

.invite-header {
  /* margin-top: -15px; */
  text-align: center;
  /* padding-top: 20px; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fff;
  display: none;
}

.invite-header hr {
  margin-top: -20px;
  border-top: 3px solid #f2f2f2;
  width: 90%;
  text-align: left;
  margin-left: 0;
}

.invite-message {
  margin-top: 30px;
  text-align: center;
  color: #60bc45;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding: 0 10px;
}

p.sign-in-text {
  margin-top: 10px;
  margin-bottom: 1rem;
  color: #b3b3b3 !important;
  font-size: 9pt !important;
  cursor: pointer;
  max-width: max-content;
  min-width: 100%;
  text-align: center;
  font-family: Urbane-Medium;
}

p.header-sign-in-text {
  margin-top: 10px;
  margin-bottom: 1px;
  color: #b3b3b3 !important;
  font-size: 7pt !important;
  cursor: pointer;
  max-width: max-content;
  min-width: 100%;
  text-align: center;
  font-family: Urbane-Medium;
}

p.header-sign-in-label {
  position: absolute;
  top: -65px;
  right: 2%;
  color: #b3b3b3;
  font-size: 15px;
}

p.header-sign-in-label:hover {
  cursor: pointer;
}

p.sign-in-label {
  padding-top: 5px;
  color: #707070;
}

p.sign-in-label:hover {
  cursor: pointer;
}

img.sign-up-button {
  margin-right: 80px;
  height: 25px;
  width: 105px;
}

img.three-dots-icon {
  position: absolute;
  right: 20px;
  display: none;
}

.btn-headersection {
  padding: 10px 75px;
  border: none;
  font-size: 30px;
  background: linear-gradient(to bottom, #9acd32 10%, #28a745 100%);
  color: #ffffff !important;
  border-radius: 10px;
  /* font-family: rift-soft; */
  font-weight: bold;
  cursor: pointer;
}

.d-inline-block.collabtab-label {
  background: #8cbf89;
  color: #fff;
  padding: 1px 5px 0px 5px;
  border-radius: 10px;
  position: absolute;
  top: -35px;
  right: 10%;
  z-index: 999;
  min-height: 42px;
  min-width: 110px;
  line-height: 17px;
  text-align: center;
  border: 2px solid #fdfcfc;
  box-sizing: border-box;
}

.bottom-arrow {
  border-bottom: 0px solid #8cbf89;
}

.bottom-arrow::after {
  content: "";
  position: absolute;
  left: 75px;
  top: 37px;
  z-index: 999999;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 7px solid #8cbf89;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

p.signup-join-text-label {
  font-size: 11px;
  margin-bottom: 0px;
  font-family: Urbane-Medium;
}

p.collabtab-text-label {
  margin-bottom: 0;
  font-size: 20px;
  font-family: RiftSoft-Bold;
}

.btn-headersection::after {
  background: linear-gradient(to bottom, red 10%, blue 100%);
}
.invite-header p {
  font-size: 16px;
  color: #f26739;
}

.invite-header h3 {
  margin-bottom: 45px !important;
  text-transform: uppercase;
  color: rgb(233, 89, 45);
}

img.feed-header-section-dividor {
  position: fixed;
  top: 65px;
  height: 15px;
  width: 100%;
  z-index: 999;
  margin-left: -15px;
}

.owner-detail {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  margin-left: -15px;
  margin-right: -15px;
}

.owner-detail .btn-headersection {
  padding: 0px 20px !important;
  border: none;
  font-size: 12px !important;
  background: linear-gradient(to bottom, #9acd32 10%, #28a745 100%);
  color: #ffffff !important;
  border-radius: 10px;
  margin-bottom: 0;
  font-weight: bold;
  margin: 0 auto;
}

img.owner-profile {
  height: 75px;
  width: 75px;
  background-color: transparent;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #f79868;
  background: #ffffff;
  /* margin-left: 90px; */
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
}

.owner-detail .owner-name {
  font-size: 16px;
  font-family: RiftSoft-Bold;
  color: #c2bcaf;
  display: inline-flex;
  align-items: flex-end;
  /* margin-left: 85px; */
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 25px;
  text-transform: uppercase;
}

.feed-post-footer pre p {
  font-size: 16px;
  font-weight: 300;
  word-break: break-word;
}

pre.post-description {
  text-align: justify;
  padding: 0px 10%;
  font-family: Urbane-Medium;
  color: #707070;
}

.single-post-description {
  word-break: break-all;
  padding-top: 246px;
  padding-left: 10px;
  padding-right: 10px;
}

.feed-post-footer pre span:hover {
  cursor: pointer;
}

.invite-header .path-slope .col-path-1:before {
  left: 10% !important;
}
.invite-header .path-slope .col-path-2:before {
  right: 10% !important;
}

.custom-dialog {
  transform: translate(0, 0) !important;
}

.options-overlay-dialog {
  transform: translate(0, 0) !important;
  height: 100%;
  margin: 0;
  background-color: rgba(214, 210, 200, 0.7);
  max-width: 767px;
}

.options-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.options-overlay-dialog .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  border: 0px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(17, 14, 5, 0);
}

.get-paths-overlay-dialog {
  height: 100%;
}

.get-paths-overlay-dialog .modal-content {
  overflow: hidden;
  position: absolute;
  bottom: 20%;
  transform: translate(-50%, -50%);
  top: 55%;
  left: 50%;
  height: max-content;
}

.modal-lg.get-paths-overlay-dialog.modal-dialog .close {
  width: 2rem;
  float: right;
  font-size: 30px;
}

.get-paths-overlay {
  padding: 10%;
}

.signup-animated-paths-logo {
  text-align: center;
}

.signup-animated-paths-logo img {
  max-width: 35%;
}

.get-paths-overlay-logo {
  text-align: center;
}

.get-paths-overlay-logo img {
  max-width: 50%;
}

.get-paths-overlay-logo p {
  font-size: 35px;
  font-family: Urbane-Medium;
  padding: 20px 0px;
  margin-bottom: 0px;
}

.social-media-buttons {
  background: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 50%; */
  padding: 10px;
}

.signup-image {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin: 25px 25px 0;
  /* width: 110px; */
}

.invite-postheader .btn-headersection {
  z-index: 9;
  /* width: 110px; */
  position: relative;
  margin-bottom: 15px;
  padding: 10px 5px;
  font-size: 1.5rem;
  max-width: 400px;
  width: 90%;
}

.invite-postheader h3 {
  margin-bottom: 45px !important;
  text-transform: uppercase;
  color: rgb(233, 89, 45);
}

.invite-postheader .path-slope .col-path-1:before {
  left: 0% !important;
}

.invite-postheader .path-slope .col-path-2:before {
  right: 0% !important;
}

img.header-image {
  height: 60px;
  width: 60px;
  background-color: transparent;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background: #ffffff;
}

img.header-back-arrow-image {
  position: absolute;
  top: 15px;
  left: 14px;
  height: 20px;
  width: 19px;
  object-fit: contain;
}

.header-paths-user-name {
  color: #707070 !important;
  font-size: 12px !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.header-paths-user-first-last-name {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  font-family: RiftSoft-Bold;
  font-size: 16px !important;
  color: #c2bcaf;
}

.col.text-right.d-flex.justify-content-end.post-header-title {
  overflow: hidden;
}

.main-header {
  z-index: 0 !important;
}
.custom-dialog .modal-header {
  height: 120px !important;
  border: none !important;
}
.custom-dialog .modal-header button {
  position: absolute !important;
  right: 3% !important;
  top: 1% !important;
  border: 1px solid !important;
  border-radius: 50% !important;
}

.header-close {
  height: 100px;
  text-align: right;
}

.signup-text {
  margin-bottom: 20px;
}

.header-detail {
  border-left: 111px solid transparent;
  height: 0;
  width: 0;
  border-right: 111px solid transparent;
  border-bottom: 120px solid rgb(233, 89, 45);
  /* background: #f26739; */
  width: 100;
  /* text-align: center; */
  padding-left: -1px;
  position: absolute;
  transform: rotate(315deg);
  left: -75px;
  top: -25px;
  z-index: 1;
  color: #ffffff;
}

.confirm-dialog {
  transform: translate(0, 0) !important;
  max-width: 320px;
  margin: 1.75rem auto;
}

.confirm-dialog .modal-content {
  border-radius: 15px;
}

.confirm-dialog .modal-header {
  border-bottom: none;
}
.confirm-dialog .modal-title {
  text-align: center;
  font-weight: bold;
  color: #f19a7e;
  font-family: RiftSoft-Bold;
}
.confirm-dialog .modal-body {
  color: #f19a7e;
  padding: 0 20px;
  font-family: Urbane-Medium;
  text-align: center;
}

.confirm-dialog .modal-footer {
  border-top: none;
  justify-content: center;
  font-family: Urbane-Medium;
}

.confirm-dialog .modal-footer button {
  background: #f19a7e;
  border: none;
  border-radius: unset;
}

.custom-dialog .btn-signup-wrapper label {
  max-width: none !important;
}

.signup-modal {
  margin-top: 30px !important;
  display: flex;
  justify-content: center;
  /* margin-bottom: 30px; */
}
.signup-modal .signup-btn {
  padding: 4px 25px;
  background-color: #8cbf89;
}

.signup-modal .btn-loader {
  font-size: x-large !important;
  padding-top: 7px;
}

.confirm-dialog .modal-header .close {
  position: fixed;
  right: 15px;
  top: 10px;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: black center center no-repeat;
  border-radius: 20%;
  z-index: 9;
}

.btn-width {
  width: 90px !important;
  height: 35px;
}

.invite-postheader .logoutalert {
  max-width: unset !important;
  top: 55% !important;
  width: 98% !important;
  right: unset !important;
  margin: 5px !important;
}

.corner-tag-main {
  position: absolute;
  top: 0px;
  left: -170px;
  z-index: 9;
}

.corner-tag-sub {
  height: 0px;
  border-style: inset;
  border-width: 0px 100px 120px 140px;
  border-color: rgb(233, 89, 45) rgb(247, 152, 104) rgb(233, 89, 45)
    rgb(247, 152, 104);
  transform: rotate(315deg) !important;
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  color: #ffffff;
}

.corner-tag-sub p {
  text-align: center;
  top: 45px;
  left: 20px;
  position: relative;
  width: 100px;
  margin: 0px;
  color: #ffffff;
}

.corner-tag-sub b {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  /* font-family: 'rift-soft'; */
  font-size: 18px;
}

.close-collab-dialog {
  position: relative;
  text-align: right;
  padding: 5px 10px 0 0;
}

.custom-dialog .modal-content button.close {
  height: 2rem;
  width: 2rem;
  background: #fff;
  position: absolute;
  right: 15px;
  top: 7px;
  border-radius: 50%;
  opacity: 22;
  font-size: 20px;
  border: 1px solid#585858;
  color: #585858;
  z-index: 9;
}

.post-header-main {
  background: #f19a7e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-memory-post-header-main {
  background: #f19a7e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.code-header-main {
  background: #f19a7e;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-post-code-header-main {
  background: #f19a7e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

.post-header-text {
  flex: 1;
  text-align: center;
  color: #ffffff;
  padding: 5px 0;
  min-height: 75px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.create-memory-post-header-text {
  flex: 1;
  text-align: center;
  color: #ffffff;
  padding: 5px 0;
  min-height: 55px;
  max-width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.btn-refresh {
  margin-top: -15px;
  font-size: 18px;
}
.btn-refresh a {
  cursor: pointer;
}

.create-memory-post-navigation {
  padding-top: 0px !important;
}

.collabtab-list {
  overflow-x: auto;
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow-y: hidden;
  padding-top: 35px;
  /* -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll; */
}

/* .collabtab-list::-webkit-scrollbar {
  display: none;
} */

.collabtab-list li {
  display: inline-block;
  vertical-align: middle;
}

.collabtab-list li a {
  position: relative;
}

.collabtab-list li .private-icon {
  position: absolute;
  font-size: 24px;
  bottom: 15px;
  right: 5px;
  z-index: 100;
  color: #f26739;
}

.collabtab-list li.active .private-icon {
  color: #fff;
  right: 10px;
}

.teachable-gratitude-title a {
  color: #f19a7e !important;
  word-break: break-word;
}

.link-list-title a {
  color: #f19a7e !important;
  word-break: break-word;
}

.tt-gj-icon i {
  font-size: 24px !important;
  transform: rotate(-45deg);
  color: #ef3c23 !important;
  margin-left: 5px;
}

.country-code,
.PhoneInputCountrySelect {
  width: 30%;
  background: none;
  padding: 4px 10px 5px 10px !important;
  border-radius: 40px;
  margin: 10px;
  margin-bottom: 0px !important;
  border-color: #f26739;
  color: #f26739;
  width: 30%;
  margin-left: 0;
}

.post-header-title {
  color: #ffffff;
  word-break: break-word;
}

.header-paths-user-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.welcome-dialog-header {
  text-align: center;
  margin: 15px 5px;
  text-transform: uppercase;
  font-weight: 400;
  color: #f26739;
}

.modal-lg.custom-dialog.modal-dialog .modal-content {
  overflow: visible;
}

.welcome-dialog-header p {
  color: gray;
  margin: 0;
}

.welcome-dialog-header p span {
  display: block;
}

.thanks-footer {
  flex-wrap: wrap;
  padding: 19px;
  text-align: center;
  justify-content: center;
  border-top: solid 1px #cecece;
  margin-top: 20px;
}

.thanks-footer p {
  flex: 1;
  min-width: 100%;
}

.thanks-footer button {
  background: #f26739;
  border-radius: 40px;
  border: none;
  height: 34px;
  color: #fff;
  width: 100%;
}

.thanks-footer .paths-phone .PhoneInputCountry {
  width: 100%;
  max-width: 70px;
  min-width: 60px;
}

.thanks-footer .phone-code {
  left: 90px;
}

.confirmation-loader {
  background: #f26739;
  border-radius: 40px;
  margin-top: 10px;
  padding: 5px 10px 5px 10px !important;
  margin-bottom: 0px !important;
  color: #fff;
  font-weight: 700;
}

input:hover {
  border-color: #b3b3b3;
  outline: none !important;
  border-radius: 10px;
}

.hide-password-image,
.show-password-image {
  position: absolute;
  top: 9px;
  right: 9px;
  height: 20px;
}

.show-password-image {
  /* position: relative;
  top: -28px;
  right: -356px; */
  height: 16px;
  /* width: 28px; */
}

@media (max-width: 767px) {
  .collabtab-list li .private-icon {
    font-size: 18px;
    bottom: 10px;
  }

  img.three-dots-icon {
    position: absolute;
    right: 15px;
    display: block;
  }

  img.post-header-divider-img {
    display: block;
    margin-top: -40px;
  }

  .paths-sign-up-content,
  .qrCodeContainerMobileResponsive {
    display: none;
  }

  .invite-postheader {
    display: block;
    background-color: #fff;
    margin-top: 70px;
  }

  .paths-web-content.col-md-6 {
    padding: 0;
  }

  .invite-header {
    text-align: center;
    /* padding-top: 20px; */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background: #fff;
    display: block;
    margin-top: 77px;
  }

  .col-md-3.paths-user-profile {
    display: none;
  }

  p.user-profile-card-header-text {
    font-size: 13px !important;
    font-weight: bold;
    color: #f19a7e;
    /* font-family: 'rift-soft'; */
    padding: 4px 0px 0px 10px;
    text-align: initial;
  }

  .collabtab-list {
    overflow-x: auto;
    white-space: nowrap;
    display: block;
    width: 100%;
    overflow-y: hidden;
    text-align: left;
  }

  .qr-code-header {
    /* padding: 10px 0px; */
    transform: scale(0.4) rotate(-5deg) translate(130px, -65px);
    box-shadow: 0 0 15px #ccc;
    margin-top: -30px;
    margin-bottom: -30px;
    max-height: 230px;
    max-width: 200px;
    min-width: 200px;
    width: 100%;
  }

  p.single-post-description.user-profile-card {
    word-break: break-all;
    /* padding-top: 246px; */
    padding-left: 10px;
    padding-right: 10px;
    font-size: 9px;
    padding-bottom: 5px;
    text-align: initial;
    color: #707070;
  }

  .paths-user-profile.col-md-3 {
    display: none;
  }

  .paths-sign-up-content.col-md-3 {
    display: none;
  }

  .add-me-icon {
    height: 35px;
    width: 40px;
    object-fit: revert;
  }

  .terms-privacy-policy {
    padding: 0px 15px 0px 15px;
    font-size: 10px;
    color: #707070;
    font-family: Urbane-Medium;
  }

  h2.text-header.text-center.mt-3.activate-account {
    font-size: 20px !important;
  }

  h2.text-header.text-center.mt-3.sign-up {
    font-size: 20px !important;
  }

  p.signup-header-top {
    font-size: 6px;
  }

  h2.text-header.text-center.mt-3.confirm-pin {
    font-size: 25px !important;
  }

  .paths-phone .PhoneInputCountrySelectArrow {
    color: #f19a7e;
    font-size: 20px;
    font-weight: bold;
    opacity: 1;
    border-right-width: 3px;
    border-bottom-width: 3px;
    width: 7px;
    height: 7px;
    margin-left: 3px;
  }

  .phone-code {
    position: absolute;
    top: 0px;
    left: calc(20% + -13px);
    background: #cecece;
    padding: 3px 5px;
    border: solid 1px #b3b3b3;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 30px;
    min-width: 40px;
    text-align: center;
    color: #585858;
    border-right: none;
  }

  .paths-phone input.PhoneInputInput {
    padding-left: 45px;
    height: 30px;
  }

  p.signup-pin-header {
    color: #f19a7e;
    /* font-family: 'urbane-rounded'; */
    padding: 0px 25px 0px 25px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    font-size: 12px;
    margin-top: 20px;
  }

  .feed-navigation.qr-code {
    margin-left: 0px;
  }

  .post-header-main.qr-code {
    margin-left: 0px;
    margin-right: 0px;
  }

  .feed-post-carousel.qr-code {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }

  a.nav-link.anchor_style.user-profile-card {
    border-radius: 0px 15px 0 0 !important;
    padding: 1px 10px !important;
  }

  .collab-tags-header {
    padding: 40px 25px;
    margin-right: 0px;
    margin-left: 0px;
    background-color: #fbf9f6;
  }

  .review-purchase-btn {
    background-color: #8cbf89;
    border-radius: 10px !important;
    color: #fff;
    border: solid #8cbf89;
    flex: 2;
    padding: 0px 15px;
    font-size: 20px;
    font-weight: bold;
  }

  .review-purchase-disabled-btn {
    background-color: #8cbf89;
    border-radius: 10px !important;
    color: #fff;
    border: solid #8cbf89;
    flex: 2;
    padding: 0px 86px;
    font-size: 20px;
    font-weight: bold;
    opacity: 0.5;
  }

  .make-payment-btn {
    background-color: #8cbf89;
    border-radius: 10px !important;
    color: #fff;
    border: solid #8cbf89;
    flex: 2;
    padding: 0px 35px;
    font-size: 20px;
    font-weight: bold;
  }

  .order-detail-title-uri {
    padding: 0px 0px 0px 10px;
  }

  img.pay_pal_image {
    width: 400px;
    height: 28px;
    object-fit: contain;
  }

  .collab-tags-done-content {
    color: #707070;
    font-size: 15px;
    text-align: center;
    padding-bottom: 20px;
  }

  .order-done-btn {
    background-color: #f19a7e;
    border-radius: 10px !important;
    color: #fff;
    border: solid #f19a7e;
    flex: 2;
    padding: 0px 40px;
    font-size: 20px;
    font-weight: bold;
  }

  .purchase-btn {
    background-color: #8cbf89;
    border-radius: 10px !important;
    color: #fff;
    border: solid #8cbf89;
    flex: 2;
    padding: 0px 15px;
    font-size: 20px;
    font-weight: bold;
  }

  .edit-order-uri {
    font-size: 15px;
    font-weight: bold;
    color: #b3b3b3;
    padding-right: 5px;
  }

  .edit-order {
    float: right;
    padding: 5px 5px 40px 0px;
    font-size: 15px;
    font-weight: bold;
    color: #b3b3b3;
  }
}

@media (max-width: 375px) {
  .name-container .input-btn {
    padding-right: 50px !important;
  }
}

@media (max-width: 441px) {
  .footer-marketplace-icons img {
    height: 30px;
    width: 54px;
    object-fit: contain;
  }
}

@media (max-width: 340px) {
  .footer-section-navigation-links p {
    font-size: 6px;
    margin-bottom: 0px;
    padding-bottom: 10px;
  }

  .footer-navigation-links span {
    padding-right: 10px;
    font-family: Urbane-Medium;
    color: #492409;
    font-size: 10px;
  }

  .footer-marketplace-icons img {
    height: 30px;
    width: 45px;
    object-fit: contain;
  }

  img.tt-public-privacy {
    position: absolute;
    right: 25px;
  }
}

@media (max-width: 399px) {
  .footer-section-navigation-links p {
    font-size: 6px;
    margin-bottom: 0px;
    padding-bottom: 10px;
  }

  .detail-footer-section-navigation-links p {
    font-size: 6px;
    margin-bottom: 0px;
  }

  .footer-navigation-links span {
    padding-right: 15px;
    font-family: Urbane-Medium;
    color: #492409;
    font-size: 10px;
  }
}

@media (max-width: 388px) {
  img.welcome-overlay-paths-logo {
    height: 60px;
    width: 150px;
    object-fit: contain;
  }

  .welcome-overlay-welcome-message h1 {
    color: #f19a7e;
    text-transform: uppercase;
    font-weight: 700;
    font-family: RiftSoft-Bold;
    font-size: 35px;
    padding-top: 35px;
  }

  .welcome-overlay-welcome-message h3 {
    color: #f19a7e;
    text-transform: uppercase;
    font-family: "RiftSoft-Bold";
    font-weight: bold;
    margin-top: -15px;
    margin-bottom: 30px;
    font-size: 20px;
  }

  .welcome-overlay-welcome-message p {
    font-family: Urbane-Medium;
    color: #492409;
    font-size: 13px;
  }

  .welcome-overlay-welcome-message img {
    height: 65px;
    width: 55px;
    object-fit: contain;
  }

  .welcome-overlay-further-guide p {
    font-size: 14px;
    padding: 15px 0px 20px 0px;
  }
}

/* @media only screen and (max-width: 1024px) {
  .radio {
    margin: 0.5rem;
    flex: 1;
    min-width: 170px;
  }
} */

@media (max-width: 320px) {
  img.header-image {
    height: 50px;
    width: 50px;
    background-color: transparent;
    object-fit: fill;
    border-radius: 50%;
    /* margin-left: 50px; */
    /* margin-bottom: 10px; */
    border: 1px solid #ffffff;
    background: #ffffff;
    /* margin-top: 20px; */
  }

  .ship-address-icon-uri {
    padding: 10px 0px 10px 0px;
    object-fit: contain;
    height: 40px;
    width: 50px;
  }

  p.get-collab-tags-text-uri {
    padding: 15px 0px 10px 0px;
    color: #c2bcaf;
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 10px;
  }

  .collab-tags-shiping-header-uri {
    color: #707070;
    padding-left: 8px;
    font-size: 12px;
    text-align: left;
    padding-right: 5px;
  }

  .multi-qr-code-uri {
    width: 200px;
    height: 129px;
    object-fit: fill;
  }

  .purchase-btn {
    background-color: #8cbf89;
    border-radius: 10px !important;
    color: #fff;
    border: solid #8cbf89;
    flex: 2;
    padding: 0px 7px;
    font-size: 20px;
    font-weight: bold;
  }
}

@media print {
  @page {
    size: A4 landscape;
  }

  /* .profile-header-web-feed, .user-info, .user-info-icons, .paths-web-content, .paths-sign-up-content, .top-header, .container, .qr-code-container, .download-qr-link {
    display: none;
  } */

  .sigle-feed-post.col-md-6,
  .user-profile-qr-code-container,
  .download-collabtag-now,
  .back-to-entry-from-collab,
  .back-to-entry-from-collab,
  #footer,
  .sign-in-text,
  .btn-headersection,
  .hidden-qr-code .downloadable-qrCodeContainer,
  .header-paths-user-first-last-name,
  .header-paths-user-name,
  .invite-header img,
  .error.error-showLogin,
  .owner-profile,
  .collab-tag-background-image-section,
  .collab-tag-operations-header,
  .collab-tag-operations-footer,
  .feed-post,
  .paths-sign-up-content.col-md-3,
  .top-header,
  .container,
  .qr-code-container,
  .profile-header-web-feed,
  .qr-links,
  .logout,
  .download-qr-link,
  .col-9.user-info,
  .col-3.user-info-icons {
    display: none !important;
  }

  .hidden-qr-code {
    display: block;
  }

  .qr-code-header {
    transform: rotate(0deg) translate(70px, 70px);
    box-shadow: 0 0 15px #ccc;
    max-width: 250px;
    max-height: 400px;
  }

  .qr-code-header-without-margin {
    transform: scale(1.1) rotate(0deg) translate(25px, 25px);
    break-inside: avoid;
    max-width: 300px;
    min-height: 100px;
    max-height: 110px;
    /* width: 90%;
    height: 50%; */
    page-break-after: always;
    margin: auto;
    -webkit-print-color-adjust: exact !important;
    background-color: #ffffff;
  }

  p.user-profile-card-header-text-without-margin {
    font-size: 20px;
    line-height: 20px;
    padding: 5px;
  }

  p.post-title.user-profile-card-without-margin {
    font-size: 20px;
    word-break: break-word;
  }

  span.post-user-name.user-profile-card-without-margin {
    font-size: 15px;
  }

  p.single-post-description.user-profile-card-without-margin {
    font-size: 15px;
    padding: 0px 5px;
    margin-bottom: 0px;
  }

  .post-header-text.user-profile-card-high-resolution {
    min-height: 50px;
  }

  img.user-profile-card-header-icon-without-margin {
    height: 50px;
    width: 50px;
    object-fit: contain;
    margin: 0px;
  }

  .circle-without-margin {
    height: 50px;
    width: 50px;
    object-fit: contain;
  }

  img.add-me-icon.user-profile-card-without-margin {
    height: 50px;
    width: 50px;
    object-fit: contain;
  }

  p.post-place.user-profile-card {
    margin-bottom: 0px;
  }

  .paths-web-content {
    background-color: #fff;
  }

  html,
  body {
    border: 1px solid white;
    height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
    -ms-zoom: 1.665;
  }

  .collab-tag-operations {
    display: none;
  }
}

.fade.in {
  opacity: 1;
  height: 100%;
  width: 100%;
}

.page-not-found {
  padding-top: 2%;
}

img {
  max-width: 100%;
}
.nav li {
  position: relative;
}

.modal-content {
  overflow: hidden;
}
.forgot-password-header {
  text-align: center;
  width: 100%;
  color: #f19a7e;
  text-transform: uppercase;
  font-family: "RiftSoft-Bold";
  font-size: 25px;
  margin-bottom: 30px;
}
.submit-btn {
  padding: 4px 25px;
  background-color: #8cbf89;
  border-radius: 10px !important;
  color: #fff;
  border: solid #8cbf89;
  flex: 2 1;
  font-weight: 700;
  font-size: 20px;
  font-family: RiftSoft-Bold;
}

.user-location {
  line-height: 21px;
  font-weight: 500;
  font-size: 14px;
}
.panel {
  background-color: #fff;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  word-wrap: break-word;
}
.img-circle {
  border-radius: 50% !important;
}
